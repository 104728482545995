import React, { Component } from "react";
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Footer extends Component {
  render() {
    return (
      <footer className="footer text-center bg-light">
        <section class="about section-padding" id="about">
          <div class="container-fluid">
            <div class="container">
              <div class="heading text-center">
                <div
                  class="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.3s"
                >
                  <h6 class="sub-title">Disclaimer</h6>
                  <h2 class="title">Disclaimer</h2>
                </div>

                <p
                  class="content-desc animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.4s"
                >
                  Web3bits is a Web3 Legal Engineering product from Global REO
                  XVIII LLC, a USA Nevada Limited Liability registered in
                  California. Web3vm is not a law firm and no Web3vm product is
                  intended to provide legal advice. Legal advice should only be
                  provided by a licensed attorney. Unless otherwise stated in
                  writing, all conversations and communications with any Web3vm
                  representative which takes place in any public, semi-public or
                  online forum (e.g. Telegram, Discord, Twitter, and
                  CryptoVoxels) is not considered legal advice and should be
                  viewed as educational based entertainment.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="container1">
          <div className="row text-center ml-2 mr-2">
            <div className="col-lg-12 h-100 text-center text-lg-center my-auto">
              <ul className="list-inline mb-2">
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">WorldLaw</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Users</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Bluefin</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Gumshoe</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Proofer</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Tidbits</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Judiciary</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 h-100 text-center text-lg-center my-auto">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-twitter-square fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-github fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-bitcoin fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-ethereum fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-gas-pump fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-dice-d6 fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-upload fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-file-download fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-user-tie fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-shield-alt fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-landmark fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-sliders-h fa-2x fa-fw "></i>
                  </a>
                </li>
              </ul>
              <div className="textdesc text-center mt-3">
                <emblack>
                  <strong>
                    Copyright &copy; 2020 - Web3 Legal Engineering -
                    Web3Engineering.com - Web3vm.eth
                  </strong>
                </emblack>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
