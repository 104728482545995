import React, { Component } from 'react';

export default class FutureToken extends Component {


render() {
return (<div className="worldlaw-form">
<div className="worldlaw-section">
<span className="worldlaw-section__title">
Benefactor eContact?
</span>
<div className="worldlaw-field worldlaw-field-text">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Benefactor?
</span>
<input
placeholder="Benefactor?"
title="Benefactor?"
className="worldlaw-field__input Company-Name"
type="text"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-yesno">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Beneficiary?
</span>
</label>
<div>
<label>
<input
className="worldlaw-field__radio Company-Formation"
name="Company-Formation"
type="radio"
defaultValue="true"
/>
<span>Yes</span>
</label>
<label>
<input
className="worldlaw-field__radio Company-Formation"
name="Company-Formation"
type="radio"
defaultValue="false"
/>
<span>No</span>
</label>
</div>
</div>
<div className="worldlaw-field worldlaw-field-identity">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Beneficiary eContact?
</span>
<input
placeholder="Benefactor eContact?"
title="Email for Benefactor eContact?"
className="worldlaw-field__input Benefactor-eContact?"
type="email"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-ethaddress">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Beneficiary Web3 Address?
</span>
<input
placeholder="Benefactor?"
title="Benefactor Web3 Address?"
className="worldlaw-field__input Benefactor-Web3-Address"
type="text"
defaultValue
/>
</label>
</div>
</div>
<div className="worldlaw-section">
<span className="worldlaw-section__title">Describe the Beneficiary</span>
<div className="worldlaw-field worldlaw-field-text">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
BenefactorAmount
</span>
<input
placeholder="Beneficiary?"
title="Name of the Student?"
className="worldlaw-field__input Student-Name"
type="text"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-identity">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Level?
</span>
<input
placeholder="Beneficiary eContact?"
title="Email for Student signature? 📧"
className="worldlaw-field__input Student-Email"
type="email"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-ethaddress">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
LevelProvider?
</span>
<input
placeholder="Beneficiary Web3 Address?"
title="Student's Ethereum address?"
className="worldlaw-field__input Student-ETH-Address"
type="text"
defaultValue
/>
</label>
</div>
</div>
<div className="worldlaw-section">
<span className="worldlaw-section__title">Describe the Terms</span>
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
MinPeriodicPmts?
</span>
<input
placeholder="BenefactorAmount"
className="worldlaw-field__input Company-Financial-Support"
type="number"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-largetext">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
SharePct?
</span>
<textarea
placeholder="Level?"
title="Level?"
className="worldlaw-field__textarea Level"
defaultValue={""}
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-text">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
PaymentCap?
</span>
<input
placeholder="LevelProvider?"
title="LevelProvider?"
className="worldlaw-field__input Education-Provider"
type="text"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
PeriodicPayments
</span>
<input
placeholder="Min Periodic Pmts?"
className="worldlaw-field__input Min-Periodic-Pmts"
type="number"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Owners
</span>
<input
placeholder="Share Pct?"
className="worldlaw-field__input Share-Pct"
type="number"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">Payment Cap?</span>
<input
placeholder="Payment Cap?"
className="worldlaw-field__input Payment-Cap"
type="number"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">Payment Cap?</span>
<input
placeholder="Periodic Payments"
className="worldlaw-field__input Periodic-Payments"
type="number"
defaultValue
/>
</label>
</div>
<div className="worldlaw-collection Owners">
<div className="worldlaw-collection__description">
<span>Owners</span>
</div>
<div className="worldlaw-collection__row">
<div className="worldlaw-field worldlaw-field-ethaddress">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">Owners</span>
<input
placeholder="Owners"
title="Owners"
className="worldlaw-field__input Owners_0"
type="text"
defaultValue
/>
</label>
</div>
<button
aria-hidden="true"
className="worldlaw-collection__button-remove"
title="Remove Collection item 0 for Owners"
type="button"
>
<svg
height={12}
width={12}
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 384 512"
>
<path
fill="currentColor"
d="M323.1 441l53.9-53.9c9.4-9.4 9.4-24.5 0-33.9L279.8 256l97.2-97.2c9.4-9.4 9.4-24.5 0-33.9L323.1 71c-9.4-9.4-24.5-9.4-33.9 0L192 168.2 94.8 71c-9.4-9.4-24.5-9.4-33.9 0L7 124.9c-9.4 9.4-9.4 24.5 0 33.9l97.2 97.2L7 353.2c-9.4 9.4-9.4 24.5 0 33.9L60.9 441c9.4 9.4 24.5 9.4 33.9 0l97.2-97.2 97.2 97.2c9.3 9.3 24.5 9.3 33.9 0z"
/>
</svg>
<span>Remove Collection item 0 for Owners</span>
</button>
</div>
<button className="worldlaw-button" type="button">
Add
</button>
</div>
<div className="worldlaw-collection Issuance">
<div className="worldlaw-collection__description">
<span>List Owner Allocations:</span>
</div>
<div className="worldlaw-collection__row">
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
List Owner Allocations
</span>
<input
placeholder="List Owner Allocations"
className="worldlaw-field__input Issuance_0"
type="number"
defaultValue
/>
</label>
</div>
<button
aria-hidden="true"
className="worldlaw-collection__button-remove"
title="Remove Collection item 0 for List Owner Allocations: "
type="button"
>
<svg
height={12}
width={12}
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 384 512"
>
<path
fill="currentColor"
d="M323.1 441l53.9-53.9c9.4-9.4 9.4-24.5 0-33.9L279.8 256l97.2-97.2c9.4-9.4 9.4-24.5 0-33.9L323.1 71c-9.4-9.4-24.5-9.4-33.9 0L192 168.2 94.8 71c-9.4-9.4-24.5-9.4-33.9 0L7 124.9c-9.4 9.4-9.4 24.5 0 33.9l97.2 97.2L7 353.2c-9.4 9.4-9.4 24.5 0 33.9L60.9 441c9.4 9.4 24.5 9.4 33.9 0l97.2-97.2 97.2 97.2c9.3 9.3 24.5 9.3 33.9 0z"
/>
</svg>
<span>Remove Collection item 0 for List Owner Allocations: </span>
</button>
</div>
<button className="worldlaw-button" type="button">
Add
</button>
</div>
</div>
</div>);
}
}
