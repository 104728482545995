import React, { Component } from 'react';

export default class NewToken extends Component {

render() {
return (<div className="worldlaw-form">
<div className="worldlaw-section">
{/* <span className="worldlaw-section__title">🌐⛓️⚖️</span> */}

</div>
<div className="worldlaw-section">
<span className="worldlaw-section__title">x</span>
<div className="worldlaw-field worldlaw-field-text">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Choice Name? (Name Choice)?
</span>
<input
placeholder="Choice Name? (Name Choice)?"
title="Token Name"
className="worldlaw-field__input Token-Name"
type="text"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-text">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Symbol? (Symbol)?
</span>
<input
placeholder="Symbol? (Symbol)?"
title="What are your Initials? (Tkn Symbol)"
className="worldlaw-field__input Token-Symbol"
type="text"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-ethaddress">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Launch Web3 Address?
</span>
<input
placeholder="Launch Web3 Address?"
title="Launch Web3 Address?"
className="worldlaw-field__input Launch-Web3-Address"
type="text"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-identity">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
eContact?
</span>
<input
placeholder="eContact?"
title="What is your eContact?"
className="worldlaw-field__input eContact"
type="email"
defaultValue
/>
</label>
</div>
</div>
<div className="worldlaw-section">
<span className="worldlaw-section__title">x</span>
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Unit Supply?
</span>
<input
placeholder="Unit Supply?"
className="worldlaw-field__input Unit-Supply"
type="number"
defaultValue
/>
</label>
</div>
<div className="worldlaw-field worldlaw-field-number">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Fixed Unit Cap?
</span>
<input
placeholder="Fixed Unit Cap?"
className="worldlaw-field__input Fixed-Unit-Cap"
type="number"
defaultValue
/>
</label>
</div>
</div>
<div className="worldlaw-section">
<span className="worldlaw-section__title">x</span>
<div className="worldlaw-field worldlaw-field-yesno">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">Attach Logo?️</span>
</label>
<div>
<label>
<input
className="worldlaw-field__radio Image"
name="Image"
type="radio"
defaultValue="true"
/>
<span>Yes</span>
</label>
<label>
<input
className="worldlaw-field__radio Image"
name="Image"
type="radio"
defaultValue="false"
/>
<span>No</span>
</label>
</div>
</div>
<div className="worldlaw-field worldlaw-field-yesno">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">Attach Offers?</span>
</label>
<div>
<label>
<input
className="worldlaw-field__radio Terms"
name="Terms"
type="radio"
defaultValue="true"
/>
<span>Yes</span>
</label>
<label>
<input
className="worldlaw-field__radio Terms"
name="Terms"
type="radio"
defaultValue="false"
/>
<span>No</span>
</label>
</div>
</div>
<div className="worldlaw-field worldlaw-field-yesno">
<label className="worldlaw-field__label">
<span className="worldlaw-field__label-text">
Benefactor?
</span>
</label>
<div>
<label>
<input
className="worldlaw-field__radio WorldLaw-ToS"
name="WorldLaw"
type="radio"
defaultValue="true"
/>
<span>Yes</span>
</label>
<label>
<input
className="worldlaw-field__radio WorldLaw-ToS"
name="WorldLaw"
type="radio"
defaultValue="false"
/>
<span>No</span>
</label>
</div>
</div>
</div>
</div>)
}
}
