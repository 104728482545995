import React, { Component } from 'react';

export default class FutureToken extends Component {


  render() {
    return (<div className="openlaw-el-form">
    <div className="openlaw-el-section">
      <span className="openlaw-el-section__title">
        Describe Education Supporter
      </span>
      <div className="openlaw-el-field openlaw-el-field-text">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Name of Company providing financial support?
          </span>
          <input
            placeholder="Name of Company providing financial support?"
            title="Name of Company providing financial support?"
            className="openlaw-el-field__input Company-Name"
            type="text"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-yesno">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Is Education Supporter organized as Legal Entity?
          </span>
        </label>
        <div>
          <label>
            <input
              className="openlaw-el-field__radio Company-Formation"
              name="Company-Formation"
              type="radio"
              defaultValue="true"
            />
            <span>Yes</span>
          </label>
          <label>
            <input
              className="openlaw-el-field__radio Company-Formation"
              name="Company-Formation"
              type="radio"
              defaultValue="false"
            />
            <span>No</span>
          </label>
        </div>
      </div>
      <div className="openlaw-el-field openlaw-el-field-identity">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Email for Company signature?
          </span>
          <input
            placeholder="Email for Company signature?"
            title="Email for Company signature?"
            className="openlaw-el-field__input Company-Email"
            type="email"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-ethaddress">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Company's Ethereum address?
          </span>
          <input
            placeholder="Company's Ethereum address?"
            title="Company's Ethereum address?"
            className="openlaw-el-field__input Company-ETH-Address"
            type="text"
            defaultValue
          />
        </label>
      </div>
    </div>
    <div className="openlaw-el-section">
      <span className="openlaw-el-section__title">Describe Student</span>
      <div className="openlaw-el-field openlaw-el-field-text">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Name of the Student?
          </span>
          <input
            placeholder="Name of the Student?"
            title="Name of the Student?"
            className="openlaw-el-field__input Student-Name"
            type="text"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-identity">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Email for Student signature?
          </span>
          <input
            placeholder="Email for Student signature?"
            title="Email for Student signature?"
            className="openlaw-el-field__input Student-Email"
            type="email"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-ethaddress">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Student's Ethereum address?
          </span>
          <input
            placeholder="Student's Ethereum address?"
            title="Student's Ethereum address?"
            className="openlaw-el-field__input Student-ETH-Address"
            type="text"
            defaultValue
          />
        </label>
      </div>
    </div>
    <div className="openlaw-el-section">
      <span className="openlaw-el-section__title">Describe ISA Terms</span>
      <div className="openlaw-el-field openlaw-el-field-number">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Company Financial Support
          </span>
          <input
            placeholder="Company Financial Support"
            className="openlaw-el-field__input Company-Financial-Support"
            type="number"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-largetext">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Higher Education or Training for Student?
          </span>
          <textarea
            placeholder="Higher Education or Training for Student?"
            title="Higher Education or Training for Student?"
            className="openlaw-el-field__textarea Higher-Education-or-Training"
            defaultValue={""}
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-text">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Provider of Higher Education or Training?
          </span>
          <input
            placeholder="Provider of Higher Education or Training?"
            title="Provider of Higher Education or Training?"
            className="openlaw-el-field__input Education-Provider"
            type="text"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-number">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Minumum Monthly Amount for ISA payments?
          </span>
          <input
            placeholder="Minumum Monthly Amount for ISA payments?"
            className="openlaw-el-field__input Minimum-Monthly-Amount"
            type="number"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-number">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">
            Income Share percentage?
          </span>
          <input
            placeholder="Income Share percentage?"
            className="openlaw-el-field__input Income-Share-Percentage"
            type="number"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-number">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">ISA Payment Cap?</span>
          <input
            placeholder="ISA Payment Cap?"
            className="openlaw-el-field__input Payment-Cap"
            type="number"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-field openlaw-el-field-number">
        <label className="openlaw-el-field__label">
          <span className="openlaw-el-field__label-text">Monthly Payments</span>
          <input
            placeholder="Monthly Payments"
            className="openlaw-el-field__input Monthly-Payments"
            type="number"
            defaultValue
          />
        </label>
      </div>
      <div className="openlaw-el-collection Owners">
        <div className="openlaw-el-collection__description">
          <span>Owners</span>
        </div>
        <div className="openlaw-el-collection__row">
          <div className="openlaw-el-field openlaw-el-field-ethaddress">
            <label className="openlaw-el-field__label">
              <span className="openlaw-el-field__label-text">Owners</span>
              <input
                placeholder="Owners"
                title="Owners"
                className="openlaw-el-field__input Owners_0"
                type="text"
                defaultValue
              />
            </label>
          </div>
          <button
            aria-hidden="true"
            className="openlaw-el-collection__button-remove"
            title="Remove Collection item 0 for Owners"
            type="button"
          >
            <svg
              height={12}
              width={12}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M323.1 441l53.9-53.9c9.4-9.4 9.4-24.5 0-33.9L279.8 256l97.2-97.2c9.4-9.4 9.4-24.5 0-33.9L323.1 71c-9.4-9.4-24.5-9.4-33.9 0L192 168.2 94.8 71c-9.4-9.4-24.5-9.4-33.9 0L7 124.9c-9.4 9.4-9.4 24.5 0 33.9l97.2 97.2L7 353.2c-9.4 9.4-9.4 24.5 0 33.9L60.9 441c9.4 9.4 24.5 9.4 33.9 0l97.2-97.2 97.2 97.2c9.3 9.3 24.5 9.3 33.9 0z"
              />
            </svg>
            <span>Remove Collection item 0 for Owners</span>
          </button>
        </div>
        <button className="openlaw-el-button" type="button">
          Add
        </button>
      </div>
      <div className="openlaw-el-collection Issuance">
        <div className="openlaw-el-collection__description">
          <span>List Owner Allocations: </span>
        </div>
        <div className="openlaw-el-collection__row">
          <div className="openlaw-el-field openlaw-el-field-number">
            <label className="openlaw-el-field__label">
              <span className="openlaw-el-field__label-text">
                List Owner Allocations:
              </span>
              <input
                placeholder="List Owner Allocations: "
                className="openlaw-el-field__input Issuance_0"
                type="number"
                defaultValue
              />
            </label>
          </div>
          <button
            aria-hidden="true"
            className="openlaw-el-collection__button-remove"
            title="Remove Collection item 0 for List Owner Allocations: "
            type="button"
          >
            <svg
              height={12}
              width={12}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M323.1 441l53.9-53.9c9.4-9.4 9.4-24.5 0-33.9L279.8 256l97.2-97.2c9.4-9.4 9.4-24.5 0-33.9L323.1 71c-9.4-9.4-24.5-9.4-33.9 0L192 168.2 94.8 71c-9.4-9.4-24.5-9.4-33.9 0L7 124.9c-9.4 9.4-9.4 24.5 0 33.9l97.2 97.2L7 353.2c-9.4 9.4-9.4 24.5 0 33.9L60.9 441c9.4 9.4 24.5 9.4 33.9 0l97.2-97.2 97.2 97.2c9.3 9.3 24.5 9.3 33.9 0z"
              />
            </svg>
            <span>Remove Collection item 0 for List Owner Allocations: </span>
          </button>
        </div>
        <button className="openlaw-el-button" type="button">
          Add
        </button>
      </div>
    </div>
  </div>);
  }
}