import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';
import HeroGut1 from "./HeroGut1.js"





class Gut1 extends Component {

render() {
return (


<div className="container2 ">

<HeroGut1 />

<div className="row ml-1 mr-1 mt-5 mb-5">

<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">

<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
WorldLaw Utility Token &nbsp;
</h4>

<p className="card-text"><strong>WorldLaw Utility Token</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xa0fda74439f4b285517a5bbd594d79ebad1d29fa</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Get WorldLaw Token Now</a>
</h4>

</div>


<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmPVoqfb5cUtDD3GdQtA1UCSD8wx6tKehFPKqmgDwDQc1g" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xa0fda74439f4b285517a5bbd594d79ebad1d29fa &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
Artwork NFT &nbsp;
</h4>


<p className="card-text">><strong>Artwork NFT</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x20e55fd8956b5e27674ec193895fbfa807c14dbe</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create an Artwork NFT Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmTeZzjFBTbNeu73ey2rhrFDXMu1LpnwWkTp7UVvt6oFZp" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x20e55fd8956b5e27674ec193895fbfa807c14dbe &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-05.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Evidential NFT &nbsp;
</h4>

<p className="card-text"><strong>Evidential NFT</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x3DC4D8975781b0d6128499Eb8caDFf21B219934C</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create a Evidential NFT Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmR52NES87QEJ7UUVt4FjGWGYQvnGnepKocfvKbsoj7QP7" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x3DC4D8975781b0d6128499Eb8caDFf21B219934C &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Operating Agreement &nbsp;
</h4>

<p className="card-text"><strong>LexDAO Orangization Operating Agreement</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xd94E2DDe18Db7844242e5fBc6CaFE116e2A5965F</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create Operating Agreement Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmWMyYEUmckkmLNX1W9SJzgBrGTDx6HXMdRSnfzVnw4WQZ" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xd94E2DDe18Db7844242e5fBc6CaFE116e2A5965F &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
X5T Token &nbsp;
</h4>

<p className="card-text"><strong>Swag0x Utility Token.</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x579b5201f1fd40a2084f162A51339126FB0E88c1</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Get X5T Now</a>
</h4>

</div>


<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmTPqDbN4T18rqHB8nREj2U3jEqem8AwgUVBJ8YVtvg5tV" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x579b5201f1fd40a2084f162A51339126FB0E88c1 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
X5B Badge Token &nbsp;
</h4>


<p className="card-text">><strong>NFT Skill Badges.</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x743e27e367419d3F50855894889dA135A716464E</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Get NFT Badges Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmVkggye36SXPtDELwHRBZU4e2HKLCDyTBcHFgC2rCisfx" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x743e27e367419d3F50855894889dA135A716464E &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-07.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
AirDrop.eth &nbsp;
</h4>

<p className="card-text"><strong>Token Drops.</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xd02e3817c4e3e4fc506a953a1e72e25333cfff8a</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
AirDrop Eth and Tokens Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmR52NES87QEJ7UUVt4FjGWGYQvnGnepKocfvKbsoj7QP7" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xd02e3817c4e3e4fc506a953a1e72e25333cfff8a &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-06.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Token Exchange &nbsp;
</h4>

<p className="card-text"><strong>X5T Exchange.</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xDecEEEA2305f9eF9Cb372867d4a9A4a1bC3a15aD</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Exchange and Pool Tokens Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmX4HRd61cLgHsCABvQueeav3jnK3fLE7zGm9EwFLLZsx9" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xDecEEEA2305f9eF9Cb372867d4a9A4a1bC3a15aD &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-03.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
MyDocs.eth &nbsp;
</h4>


<p className="card-text"><strong>Public Blockchain Document Manager.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x3E489D2b3D2F30594D9B9B10EDe69C4Dcb90228d</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use MyDocs Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmbDZE9oCvjEa75FpiuSjf6TXroMm2JL7kpWkH6Fz2U4cV" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x3E489D2b3D2F30594D9B9B10EDe69C4Dcb90228d &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-04.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Images.eth&nbsp;
</h4>


<p className="card-text"><strong>Public Blockchain Image Recorder.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xDF5E21b14CD54ABB2fFd32fA6a33A0cFA86a1273</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Store Images Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmRQGMTVtRT8eU5apVuHHHjN8kfjbtZPvR8WvPHPCACik1" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xDF5E21b14CD54ABB2fFd32fA6a33A0cFA86a1273 &nbsp;
&nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>

</div>


<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-04.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Videos.eth &nbsp;
</h4>


<p className="card-text"><strong>Public Blockchain Video Recorder.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xDF5E21b14CD54ABB2fFd32fA6a33A0cFA86a1273</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Store Videos Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmRQGMTVtRT8eU5apVuHHHjN8kfjbtZPvR8WvPHPCACik1" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xDF5E21b14CD54ABB2fFd32fA6a33A0cFA86a1273 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-04.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
AudioFiles.eth &nbsp;
</h4>


<p className="card-text"><strong>Public Blockchain Audio File Recorder.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xDF5E21b14CD54ABB2fFd32fA6a33A0cFA86a1273</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Store Audio Files Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmRQGMTVtRT8eU5apVuHHHjN8kfjbtZPvR8WvPHPCACik1" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xDF5E21b14CD54ABB2fFd32fA6a33A0cFA86a1273 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-05.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Cointract Generator &nbsp;
</h4>


<p className="card-text"><strong>Create a Disputable ERC20 Agreement Generator.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x8fFb560c64269ACa8F898f47Ef55ac2f8Af5F31d</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Generate a Cointract Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/Qmcbq3kCwgp1k5tA7pRNXn6aw1aUSsvqM3PcDQEeQD4hfz" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x8fFb560c64269ACa8F898f47Ef55ac2f8Af5F31d &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-05.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Promised.eth&nbsp;
</h4>


<p className="card-text"><strong>Transact with a Disputable Buyer-Seller Agreement.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x172cf9bC8663f8998ebC1D7B1F287cc2eb07132b</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create a Promise Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmczPNryW4vaYbFe8RKzEY6UtSDCh8Zk2iDm9FsfTPnppU" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x172cf9bC8663f8998ebC1D7B1F287cc2eb07132b &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-06.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
USDC Stable eCoin&nbsp;
</h4>


<p className="card-text"><strong>Transact with the USDC stable ERC20 coin token.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use USDC Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmTjN3zRTw8Fy2gMo9aKCLaASvq5PWjDSZG86e63imW8af" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48 &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-06.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
DAI Stable eCoin&nbsp;
</h4>


<p className="card-text"><strong>Transact with the DAI stable ERC20 coin token.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x6B175474E89094C44Da98b954EedeAC495271d0F</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use DAI Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmVTc4DJmMvpJyuya8jVYgfddNMBduyYrFVMwkwN6Nq2x1" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x6B175474E89094C44Da98b954EedeAC495271d0F &nbsp;
&nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>

</div>


<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-01.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
User Account Manager &nbsp;
</h4>


<p className="card-text"><strong>Create and Manage a User Account and Identity</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x644cd05481d71116Ea43D3808Dea5B3F41ce039a</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Generate an Account Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/https://ipfs.infura.io/ipfs/QmbDZE9oCvjEa75FpiuSjf6TXroMm2JL7kpWkH6Fz2U4cV" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x644cd05481d71116Ea43D3808Dea5B3F41ce039a &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-03.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Collect and Manage Evidence &nbsp;
</h4>


<p className="card-text"><strong>Encrypt and save evidence to the public Ethereum blockchain.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x9AdA6Deb70cF2B3ca40E8f082911f82D90A4Bc7F</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Save Evidence Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmczPNryW4vaYbFe8RKzEY6UtSDCh8Zk2iDm9FsfTPnppU" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x9AdA6Deb70cF2B3ca40E8f082911f82D90A4Bc7F &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-01.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Lawyer Client Manager &nbsp;
</h4>


<p className="card-text"><strong>Manage client records and digital evidence.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x18505155a3ac174a9f3148a12b1d8fa18d2db113</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Manage Client Records Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmTjN3zRTw8Fy2gMo9aKCLaASvq5PWjDSZG86e63imW8af" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x18505155a3ac174a9f3148a12b1d8fa18d2db113 &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-05.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Proofer.eth &nbsp;
</h4>


<p className="card-text"><strong>Parse and verify records on the public Ethereum blockchain.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x3E489D2b3D2F30594D9B9B10EDe69C4Dcb90228d</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Verify Records Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmVTc4DJmMvpJyuya8jVYgfddNMBduyYrFVMwkwN6Nq2x1" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x3E489D2b3D2F30594D9B9B10EDe69C4Dcb90228d &nbsp;
&nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>

</div>

<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
LexToken Factory &nbsp;
</h4>


<p className="card-text"><strong>Generate a LexToken Factory for Private Use.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x093bC4C8918522f09b4C2a31CF9Ab2556De694C8</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create Token Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmT1cakrnHwgZ351xVspZYi416zjVS2HH3AmrXp5c4c8iw" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x093bC4C8918522f09b4C2a31CF9Ab2556De694C8 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
Gated LexToken Factory &nbsp;
</h4>


<p className="card-text">Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xe01d65AA026beFdD4771B1c9C1a9cDF93999fE72</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create Gated Token Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmWSaE6XaaaL1oXikKnDMu7fC41UHUqTse4Va6mSNXWw1c" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xe01d65AA026beFdD4771B1c9C1a9cDF93999fE72 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
LexSecurity Token Factory &nbsp;
</h4>


<p className="card-text"><strong>Generate a ERC1404 Standard Security Token for Private Use.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x697D15d97af389A5f7922eFECEab39A688A74A99</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create Secutity Token Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmVyYDSBfiQwdKUP8xZdd915u6muSXikUkHuB1w1aGjA6Q" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x697D15d97af389A5f7922eFECEab39A688A74A99 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-07.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Member DripDrop Factory &nbsp;
</h4>


<p className="card-text"><strong>Generate a DripDrop Contract for Private Use.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x7d5943dFb6fF3303728629Cf066B6D7cFb22733B</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create DripDrop Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmR49euFbWD1bwx88sJErCFUn9pdjDYe45p7hRuqLLFsCq" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x7d5943dFb6fF3303728629Cf066B6D7cFb22733B &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
LexToken Karma LXKM &nbsp;
</h4>


<p className="card-text"><strong>LexKarma is a LexToken.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x5b8E815CEF16edD8e459f3dc462D05dB19687c93</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Process LexKarma Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmS2XgUA6PAnczkeC83k54bgCHjzEhuMWbgEqMyN393X8G" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x5b8E815CEF16edD8e459f3dc462D05dB19687c93 &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
Offer Token Factory &nbsp;
</h4>


<p className="card-text"><strong>Offer Token.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xBC58ff494dA8030d8D31fAaAbfff16F7F521b977</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create Offer Token Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmThWTySiZemY2L4LD3FLNWg4LDRzezZpTL6p7f8qGL2vf" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xBC58ff494dA8030d8D31fAaAbfff16F7F521b977 &nbsp;
&nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>

</div>


<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexDAO Kudo LXK &nbsp;
</h4>


<p className="card-text"><strong>LexDAO Legal Engineering Kudo (LXK).</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x4D9D9a22458dD84dB8B0D074470f5d9536116eC5</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Process LexDAO Kudo Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmTtVKTh6JvA7xu2rV7MdCnMhpQb2aKkMqBeqrF8bs6xzH" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x4D9D9a22458dD84dB8B0D074470f5d9536116eC5 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-11.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexBank&nbsp;
</h4>


<p className="card-text"><strong>LexBank.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xDab9050983202Fb4d9941E010dF660376Fa6726F</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use LexBank Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmShMFVX8rGNQczfYPkxbA7nQNca1ZkZ6ic5RCH41caq3w" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xDab9050983202Fb4d9941E010dF660376Fa6726F &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-07.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Member DripDrop&nbsp;
</h4>


<p className="card-text"><strong>Member DripDrop.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xB1DA34fe3e512FB6afF46F27935f002957b9dD0e</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Member DripDrop Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmbzNLAt1aVRyBFM6975x9zAmTwNiudPc7zZULiedDaj4T" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xB1DA34fe3e512FB6afF46F27935f002957b9dD0e &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-07.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Drop Ether &nbsp;
</h4>


<p className="card-text"><strong>Drop Ether.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x70814357697C5290920BCa4327fE6640E22edd49</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Drop Ether Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/Qme8CCTZ1C73dQ8UAYrMPGPsQZB7hfznquLmgkmVKbrhe6" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x70814357697C5290920BCa4327fE6640E22edd49 &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-07.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Drop Tokens &nbsp;
</h4>


<p className="card-text"><strong>Drop Tokens.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xCa1D63C7173c994a8e608836806DC594Ad3b5E66</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Drop Tokens Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmdoAyeG3Nu4ABzhPYw6hP7iAefc7Go5AYEG2oBmb4pDyV" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xCa1D63C7173c994a8e608836806DC594Ad3b5E66 &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-07.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Custom Drop&nbsp;
</h4>


<p className="card-text"><strong>Custom Drop.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x4c4B2f0D065c6D207Ee18544f3277aF3c4A14C77</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Drop Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmThukfqDP8hRSP7xhoXk1SZX8z1hUm6TpCCQ8PpzoNB9A" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x4c4B2f0D065c6D207Ee18544f3277aF3c4A14C77 &nbsp;
&nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>

</div>

<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexDAO Membership &nbsp;
</h4>


<p className="card-text"><strong>LexDAO Member Token LDM.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x8C32E54439C00E2B34355b8A1590046324bEaeA7</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Manage Membership Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmS2XgUA6PAnczkeC83k54bgCHjzEhuMWbgEqMyN393X8G" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x8C32E54439C00E2B34355b8A1590046324bEaeA7 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexDAO Cheese Token &nbsp;
</h4>


<p className="card-text"><strong>LexDAO Cheese Token for Service Credit and Social Capital.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xF27d4785d7Eb818CA000A843957a1E19a6F95a19</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use LexCheese Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmS2XgUA6PAnczkeC83k54bgCHjzEhuMWbgEqMyN393X8G" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xF27d4785d7Eb818CA000A843957a1E19a6F95a19 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-01.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexChat Wrapper &nbsp;
</h4>


<p className="card-text"><strong>Generate an Encrypted On-chain Conversation.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xAF0348b2A3818BD6Bc1f12bd2a9f73F1B725448F</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Wrap a Chat Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmS2XgUA6PAnczkeC83k54bgCHjzEhuMWbgEqMyN393X8G" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xAF0348b2A3818BD6Bc1f12bd2a9f73F1B725448F &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-01.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexChat Post&nbsp;
</h4>


<p className="card-text"><strong>Respond to a Blockchain Conversation.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xA81600350ad1B6118FFEF24dBc68c9635aF549Af</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Chat Post Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmUrCKYwQR3uxP76hqqVvTpVrZ5vZSP7145c4iq6KWAMZt" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xA81600350ad1B6118FFEF24dBc68c9635aF549Af &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-05.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Ether Escrow Factory&nbsp;
</h4>


<p className="card-text"><strong>Generate an Ether Escrow Smart-Contract for Private Use.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xf8BB603941d555559A6B7B6A406DEE5Db8c8802F</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create Escrow Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmdY96MX7oxZ2tatJQkhFdKxcHmJjNkTYzspdMQWvM33tD" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xf8BB603941d555559A6B7B6A406DEE5Db8c8802F &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-05.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Ether Escrow Wrapper&nbsp;
</h4>


<p className="card-text"><strong>LexDAO LexGrow Ether Wrapper LXG.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xecA1F2b5CD3519242797A6FC3a4aEBDcc8f71907</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Wrap Escrow Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmbrEzfT3GjTEmrsxEpaPo4yop4nCrMQG4Z7nV1vBqXAob" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xecA1F2b5CD3519242797A6FC3a4aEBDcc8f71907 &nbsp;
&nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>

</div>


<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-11.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128280;</span></a>
&nbsp;
LexDAO Upgradeable Proxy &nbsp;
</h4>


<p className="card-text"><strong>LexDAO Upgradeable Proxy.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x97103fda00a2b47EaC669568063C00e65866a633</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Set Proxy Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmTkYbjHzy82eiTtLatJGt8knN4pN3psPfZ6GSLc4j3vPG" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x97103fda00a2b47EaC669568063C00e65866a633 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-11.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Terms of Service TOS Proxy &nbsp;
</h4>


<p className="card-text"><strong>Terms of Service Upgradeable Proxy.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xF68D0f4EAC085e1477946582E22a784a1a1f0bDc</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Set TOS Proxy Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmTkYbjHzy82eiTtLatJGt8knN4pN3psPfZ6GSLc4j3vPG" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xF68D0f4EAC085e1477946582E22a784a1a1f0bDc &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
LexToken Factory Maker &nbsp;
</h4>


<p className="card-text"><strong>Generate a smart-contract to manage LexToken for private use.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x80CFbE26CCA322411F9F005d25ba54127618Fcb0</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Create Factory Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmWVfTjazHQqiCyXccfXq8S8JLo5gTgQyBgPytzhEubt8m" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x80CFbE26CCA322411F9F005d25ba54127618Fcb0 &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexDAO Magick &nbsp;
</h4>

<h5><strong>Token for Participating in LexDAO Magick.</strong></h5>
<p className="card-text">Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x39B62c0Cb59DB1C4D62efBF62bb3c1BfB0cFFdBc</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use Magick Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmS2XgUA6PAnczkeC83k54bgCHjzEhuMWbgEqMyN393X8G" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x39B62c0Cb59DB1C4D62efBF62bb3c1BfB0cFFdBc &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexDAO Magick Baton One &nbsp;
</h4>


<p className="card-text"><strong>LexDAO Magick Baton Spell One.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x3eC7001Cca816FB3425f31d041148b2332d66208</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Cast Spell One Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmWN4yAskN6b3gYHXw8cHDqX4P39hF5VGttjGegotG13qH" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x3eC7001Cca816FB3425f31d041148b2332d66208 &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>




<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexDAO Magick Baton Two &nbsp;
</h4>


<p className="card-text"><strong>LexDAO Magick Baton Spell Two.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x027fa7C653fBfD1Fe49Bcf930a0E40fc429bCC72</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Cast Spell Two Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmS2XgUA6PAnczkeC83k54bgCHjzEhuMWbgEqMyN393X8G" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x027fa7C653fBfD1Fe49Bcf930a0E40fc429bCC72 &nbsp;
&nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>

</div>


<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-10.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
LexDAO Cert &nbsp;
</h4>

<p className="card-text"><strong>LexDAO Certification and Certifier.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x33Bd80F1E6Bc760fe4C07c47ae2BC4AD0C5207D7</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Certify Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmS2XgUA6PAnczkeC83k54bgCHjzEhuMWbgEqMyN393X8G" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x33Bd80F1E6Bc760fe4C07c47ae2BC4AD0C5207D7 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-09.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
TheLAO Deal Locker &nbsp;
</h4>


<p className="card-text"><strong>TheLAO Deal Locker Contract Tracker.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x8F56682a50BECB1df2Fb8136954f2062871bc7fc</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Check Status Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmaYkSsfLJHVi5hc747vLtPPcgBoqk18U7gdMP6HRAAeP8" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x8F56682a50BECB1df2Fb8136954f2062871bc7fc &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-06.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Public Liquidity Pools&nbsp;
</h4>


<p className="card-text"><strong>Interact with public liquidity pools.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use Public Pool Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmQwMwdkA8NThJoEVDq6HMu9ZNqmpbSH1DpM5VQEwgqLzZ" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95 &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-06.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
Liquidity Pool &nbsp;
</h4>


<p className="card-text"><strong>Interact with a LexDAO Liquidity Pool.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xC7F9E5C6301aFDAA02f9CbB0937e4B233ED53001</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Use Private Pool Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmdZXRvrdiRLL1awWWD96g4MmnvsCB3SRWXRDNqVVNYdqD" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xC7F9E5C6301aFDAA02f9CbB0937e4B233ED53001 &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
OpenCourt Claim Manager &nbsp;
</h4>


<p className="card-text"><strong>Interact with a LexDAO Liquidity Pool.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x0ab3c1e0ba8efa4422b0b26869583b596c2c96da</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Manage Claim Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmQTyXSWryZBy7wvvejvySrtwXMxquDfkRSvt7U8gQhPuv" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x0ab3c1e0ba8efa4422b0b26869583b596c2c96da &nbsp;
<span role="img" aria-label="lock">&#128272;</span>
<span role="img" aria-label="lock">&#128273;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
OpenCourt Judge &nbsp;
</h4>


<p className="card-text"><strong>OpenLaw Sponsored Dispute Resolution.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x17A83B1eA24942fb6a913bCB87f38035AB205b68</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Check Status Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmVtz9D2U1ZaxsUZBUNM5qJDE2e3f7ZYFDzY9LWCspAr3x" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x17A83B1eA24942fb6a913bCB87f38035AB205b68 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-08.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
OpenCourt Judgment&nbsp;
</h4>


<p className="card-text"><strong>Render a Judgment.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0x067b408EDDEea54D61172198Ae5D9077789da2A9</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Manage a Dispute</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/Qmd1nYZkYKBo8LjsKFY4wBJDyrL91UxFBqqHhkUKhMwfnb" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0x067b408EDDEea54D61172198Ae5D9077789da2A9 &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>

<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-02.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="tokens">&#128280;</span></a>
&nbsp;
Create an OpenCourt Token Factory &nbsp;
</h4>


<p className="card-text"><strong>Open Court Token Factory.</strong>Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xbC2Bef8C42B7e9F5E256D0C00C3e14f93aA2F4d1</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
Deploy New Factory Now</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmaQxuwbrJ3zBxGxocrJZLy7dn9pmTzN8t7qxhmnzvEuWL" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xbC2Bef8C42B7e9F5E256D0C00C3e14f93aA2F4d1 &nbsp;
<span role="img" aria-label="lock">&#128279;</span>
<span role="img" aria-label="lock">&#9981;</span>
</a></div>
</div>
</div>
</div>



<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
<div className="card h-100">
<a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/web3vm-07.png" alt="database"></img></a>
<div className="card-body">

<h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
&nbsp;
AirDrop.eth V2 &nbsp;
</h4>

<p className="card-text"><strong>Eth or Token Distribution.</strong> Sample text here reserved for copy writers..... Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process. </p>
<strong><u>Smart-Contract:</u> 0xD152f549545093347A162Dce210e7293f1452150</strong>
<h4 className="card-title font-weight-bold">
<a href="#smart-contracts">
<hr></hr>
AirDrop Eth and Tokens</a>
</h4>
</div>
<div className="card-footer">
<div className="text-muted"><a href="https://ipfs.infura.io/ipfs/QmSxoMBDhuW3mvFY5C95mxc688sjhARw32gbBjSb2siKan" target="_blank" rel="noopener noreferrer" >
<u>Smart-Contract ABI hyperlink: </u>0xD152f549545093347A162Dce210e7293f1452150 &nbsp;
<span role="img" aria-label="lock">&#128159;</span>
</a></div>
</div>
</div>
</div>





</div>



<div className="container2">

<div className="container3">
<div className="d-flex justify-content-center mt-5">
<div className="textdesc"><emwhite><strong>This is your Web3 account:</strong></emwhite> <emyellow><span id="account">{this.props.account}</span></emyellow>
<div className="textdesc"><emwhite><strong>Your unique Web3 account identicons are:</strong></emwhite>
{ this.props.account
? <img
className='ml-1 rounded-5'
width='48'
height='48'
src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
: <span></span>
}
{ this.props.account
? <img
className='ml-1 rounded-5'
width='48'
height='48'
src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
: <span></span>
}

</div>


</div>
</div>

</div>
</div>

</div>
);
}
}
export default Gut1;
