import React, { Component } from "react";
import FutureToken from "./FutureToken.js";
import SelfToken from "./SelfToken.js";
import Web3 from "web3";
import Identicon from "identicon.js";
import makeBlockie from "ethereum-blockies-base64";

import HeroGut9 from "./HeroGut9.js";

const web3 = new Web3(Web3.givenProvider);

const address1 = "0x99755cEBA6460491173307985ea7c0cdB0a84D7A";
const abi1 = [
  {
    inputs: [
      { internalType: "string", name: "_stamp", type: "string" },
      { internalType: "uint256", name: "_factoryFee", type: "uint256" },
      { internalType: "address", name: "_deployer", type: "address" },
      { internalType: "address payable", name: "lexDAO", type: "address" },
      { internalType: "bool", name: "certified", type: "bool" },
      { internalType: "bool", name: "_gated", type: "bool" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bool",
        name: "updatedCertification",
        type: "bool",
      },
    ],
    name: "CertificationUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "updatedFactoryFee",
        type: "uint256",
      },
    ],
    name: "FactoryFeeUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "string",
        name: "details",
        type: "string",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "payment",
        type: "uint256",
      },
    ],
    name: "LexDAOPaid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "updatedLexDAO",
        type: "address",
      },
    ],
    name: "LexDAOUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "LT", type: "address" },
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "LexTokenDeployed",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "_certified",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_lexDAO",
    outputs: [{ internalType: "address payable", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "deployer",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "factoryFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "gated",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getLexTokenCount",
    outputs: [
      { internalType: "uint256", name: "LexTokenCount", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "string", name: "name", type: "string" },
      { internalType: "string", name: "symbol", type: "string" },
      { internalType: "string", name: "_stamp", type: "string" },
      { internalType: "uint8", name: "decimals", type: "uint8" },
      { internalType: "uint256", name: "cap", type: "uint256" },
      { internalType: "uint256", name: "initialSupply", type: "uint256" },
      { internalType: "address", name: "owner", type: "address" },
    ],
    name: "newLexToken",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "string", name: "details", type: "string" }],
    name: "payLexDAO",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "stamp",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "tokens",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "bool", name: "updatedCertification", type: "bool" },
    ],
    name: "updateCertification",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "uint256", name: "updatedFactoryFee", type: "uint256" },
    ],
    name: "updateFactoryFee",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address payable",
        name: "updatedLexDAO",
        type: "address",
      },
    ],
    name: "updateLexDAO",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "version",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

//newLexToken
const contract1 = new web3.eth.Contract(abi1, address1);

// const address2 = "0x697D15d97af389A5f7922eFECEab39A688A74A99";

// const abi2 = [
//   {
//     inputs: [
//       { internalType: "uint256", name: "_factoryFee", type: "uint256" },
//       { internalType: "address payable", name: "_lexDAO", type: "address" },
//     ],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "constructor",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "factoryFee",
//     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "lexDAO",
//     outputs: [{ internalType: "address payable", name: "", type: "address" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { internalType: "string", name: "name", type: "string" },
//       { internalType: "string", name: "symbol", type: "string" },
//       { internalType: "string", name: "_stamp", type: "string" },
//       { internalType: "uint8", name: "decimals", type: "uint8" },
//       { internalType: "address", name: "_fundsToken", type: "address" },
//       { internalType: "address[]", name: "ownership", type: "address[]" },
//       { internalType: "uint256[]", name: "issuance", type: "uint256[]" },
//     ],
//     name: "newLexSecurityToken",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "version",
//     outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
// ];

// const contract2 = new web3.eth.Contract(abi2, address2)

// ==Token Info==
// const tokenInfo = {
//   TokenInitialAmount: {label: "How many Tokens to start? (Default is 1,000)", type: "number"},
//   TokenName:{  label: "What is your Name or the Token Name?", type:"text"},
//   TokenSymbol:{ label: "What are your Initials or a the 3-5 letter token call symbol?", type:"text"},
//   TokenDecimals: {label: "How many decimal places for the token? (Default is 18)"},
//   TokenInitialOwnerEthAddress: {label: "Web3 address/account?", type:"text"},
//   TokenSignatoryIdentityOrSignature: {label: "Email address to sign transaction.", type:"email"},
//   Terms: {label:"Click this button to add token terms.", type:"radio"},
//   TokenTerms: {label: "Terms:", type:"text"}
// //TokenTerms: {label: "Terms:", type:"textarea"}
// }

class Gut9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenType: 0,
    };
  }

  // arguments:Token Name, Token Symbol, info.id, 18, Token Initial Amount in Wei)]]
  generatePT = async (e) => {
    // e.preventDefault
    console.log("generate PT");
    const account = await web3.eth.getAccounts();
    const result = await contract1.methods
      .newLexToken(
        "Alphabet Token",
        "ABC",
        "0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178",
        18,
        1000,
        1000,
        "0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178"
      )
      .send({ from: account[0] });
    console.log("result", result, account);
    return false;
  };

  //   {
  //     TokenInitialAmount: {label: "How many Tokens to start?", type: "number"},
  //     TokenName:{  label: "What is your Name?", type:"text"}
  //     TokenSymbol:{ label: "What are your Initials?", type:"text"}
  //     TokenInitialOwnerEthAddress: {label: "What is your Ethereum address?", type:"text"},
  //     TokenSignatoryIdentityOrSignature: {label: "What is your Email address", type:"text"},
  //     Terms: {label:"Document Terms of Token Issuance?", type:"text"},
  //     TokenTerms: {label: "Terms:", type:"checkbox"}
  // }

  //Token Name, Token Symbol, info.id, 18, Adjusted Token Supply Cap, Adjusted Token Initial Supply, Token Issuer;

  handleChange = (e) => {
    // e.preventDefault
    console.log("tokenType", e.target.value);
    this.setState({ tokenType: e.target.value });
    return false;
  };

  loadContract = (e) => {
    return false;
  };

  render() {
    const { tokenType } = this.state;
    return (
      <div className="container2">
        <section className="call-to-action text-white mb-5">
          <HeroGut9 />

          <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
            <div className="form-row">
              <p className="bigtextgold mt-5">
                <strong>Create an Evidential NFT</strong>
              </p>
              <p class="textdesc">
                <emwhite>
                  This text is just a place holder until the copy writers step
                  in ...... The protocol retrieves blocks and chunks of data for
                  reassembly and decoding into a useable/viewable file. The
                  decoded files include electronic documents with timestamps and
                  in some cases, admissible direct testimony
                </emwhite>
              </p>
              <p>
                WorldLaw is a set of protocols that operate with public nodes,
                specifically Ethereum, Swarm and IPFS to store encrypted content
                and data-files for court use.
              </p>

              <br></br>

              <p className="bigtextgold">
                <strong>
                  A Non Fungible Token (NFT) is a structured digital asset.
                </strong>
              </p>
              <p class="textdesc">
                <emwhite>
                  Tokenization is a buzzword today and operates in uncharted
                  territory which requires wrestling with the law in the face of
                  rapid technological changes. Court systems and alternative
                  dispute resolution methods already ...........
                </emwhite>
              </p>
              <p>
                This text is just a place holder until the copy writers step in
                ......This means a client’s file is safe without having a single
                point of failure. Evidence files are taken from their point of
                origin, usually from a mobile phone or desktop computer, and
                local encrypted on that same device. .
              </p>

              <p className="bigtextgold">
                <strong>
                  Evidential NFTs satify U.S. Federal Rules of Evidence
                </strong>
              </p>
              <p class="textdesc">
                <emwhite>
                  Tokenization is a buzzword today and operates in uncharted
                  territory which requires wrestling with the law in the face of
                  rapid technological changes. Court systems and alternative
                  dispute resolution methods already ...........
                </emwhite>
              </p>
              <p>
                This text is just a place holder until the copy writers step in
                ......This means a client’s file is safe without having a single
                point of failure. Evidence files are taken from their point of
                origin, usually from a mobile phone or desktop computer, and
                local encrypted on that same device. .
              </p>

              <br></br>
              <p></p>
              <p></p>
              <p></p>
              <p className="bigtextlime">
                <strong>Create Evidential NFT Now</strong>
              </p>
              <div className="col-xl-7 col-lg-8 col-md-10 mx-auto mb-5">
                <div className="openlaw-el-field openlaw-el-field-choice">
                  <label className="openlaw-el-field__label">
                    <span className="openlaw-el-field__label-text">
                      I want to
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange}
                      className="openlaw-el-field__select I-want-to-tokenize-my"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Mint a Token</option>
                      <option value={2}>Purchase a Token</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <SelfToken />}
                {tokenType === 2 && <FutureToken />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Puchase NFT Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>

        <div className="container3">
          <div className="d-flex justify-content-center mt-5">
            <div className="textdesc">
              <emwhite>
                <strong>This is your Web3 account:</strong>
              </emwhite>{" "}
              <emyellow>
                <span id="account">{this.props.account}</span>
              </emyellow>
              <div className="textdesc">
                <emwhite>
                  <strong>Your unique Web3 account identicons are:</strong>
                </emwhite>
                {this.props.account ? (
                  <img
                    className="ml-1 rounded-5"
                    width="48"
                    height="48"
                    src={makeBlockie(this.props.account, 24).toString()}
                    alt="AcctIconImage"
                  />
                ) : (
                  <span></span>
                )}
                {this.props.account ? (
                  <img
                    className="ml-1 rounded-5"
                    width="48"
                    height="48"
                    src={`data:image/png;base64,${new Identicon(
                      this.props.account,
                      24
                    ).toString()}`}
                    alt="AcctIconImage"
                  />
                ) : (
                  <span></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gut9;
