import React, { Component } from "react";
import Identicon from "identicon.js";
import makeBlockie from "ethereum-blockies-base64";
import FutureToken from "./FutureToken.js";
import SelfToken from "./SelfToken.js";
import Choice1 from "./Choice1.js";
import Choice2 from "./Choice2.js";
import HeroGut3 from "./HeroGut3.js";

import Web3 from "web3";
const web3 = new Web3(Web3.givenProvider);

const address1 = "0x99755cEBA6460491173307985ea7c0cdB0a84D7A";
const abi1 = [
  {
    inputs: [
      { internalType: "string", name: "_stamp", type: "string" },
      { internalType: "uint256", name: "_factoryFee", type: "uint256" },
      { internalType: "address", name: "_deployer", type: "address" },
      { internalType: "address payable", name: "lexDAO", type: "address" },
      { internalType: "bool", name: "certified", type: "bool" },
      { internalType: "bool", name: "_gated", type: "bool" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bool",
        name: "updatedCertification",
        type: "bool",
      },
    ],
    name: "CertificationUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "updatedFactoryFee",
        type: "uint256",
      },
    ],
    name: "FactoryFeeUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "string",
        name: "details",
        type: "string",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "payment",
        type: "uint256",
      },
    ],
    name: "LexDAOPaid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "updatedLexDAO",
        type: "address",
      },
    ],
    name: "LexDAOUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "LT", type: "address" },
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "LexTokenDeployed",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "_certified",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_lexDAO",
    outputs: [{ internalType: "address payable", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "deployer",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "factoryFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "gated",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getLexTokenCount",
    outputs: [
      { internalType: "uint256", name: "LexTokenCount", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "string", name: "name", type: "string" },
      { internalType: "string", name: "symbol", type: "string" },
      { internalType: "string", name: "_stamp", type: "string" },
      { internalType: "uint8", name: "decimals", type: "uint8" },
      { internalType: "uint256", name: "cap", type: "uint256" },
      { internalType: "uint256", name: "initialSupply", type: "uint256" },
      { internalType: "address", name: "owner", type: "address" },
    ],
    name: "newLexToken",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "string", name: "details", type: "string" }],
    name: "payLexDAO",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "stamp",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "tokens",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "bool", name: "updatedCertification", type: "bool" },
    ],
    name: "updateCertification",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "uint256", name: "updatedFactoryFee", type: "uint256" },
    ],
    name: "updateFactoryFee",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address payable",
        name: "updatedLexDAO",
        type: "address",
      },
    ],
    name: "updateLexDAO",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "version",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

//newLexToken
const contract1 = new web3.eth.Contract(abi1, address1);

// const address2 = "0x697D15d97af389A5f7922eFECEab39A688A74A99";

// const abi2 = [
//   {
//     inputs: [
//       { internalType: "uint256", name: "_factoryFee", type: "uint256" },
//       { internalType: "address payable", name: "_lexDAO", type: "address" },
//     ],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "constructor",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "factoryFee",
//     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "lexDAO",
//     outputs: [{ internalType: "address payable", name: "", type: "address" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { internalType: "string", name: "name", type: "string" },
//       { internalType: "string", name: "symbol", type: "string" },
//       { internalType: "string", name: "_stamp", type: "string" },
//       { internalType: "uint8", name: "decimals", type: "uint8" },
//       { internalType: "address", name: "_fundsToken", type: "address" },
//       { internalType: "address[]", name: "ownership", type: "address[]" },
//       { internalType: "uint256[]", name: "issuance", type: "uint256[]" },
//     ],
//     name: "newLexSecurityToken",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "version",
//     outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
// ];

// const contract2 = new web3.eth.Contract(abi2, address2)

// ==Token Info==
// const tokenInfo1 = {
//   TokenInitialAmount: {label: "How many Tokens to start? (Default is 1,000)", type: "number"},
//   TokenName:{  label: "What is your Name or the Token Name?", type:"text"},
//   TokenSymbol:{ label: "What are your Initials or a the 3-5 letter token call symbol?", type:"text"},
//   TokenDecimals: {label: "How many decimal places for the token? (Default is 18)"},
//   TokenInitialOwnerEthAddress: {label: "Web3 address/account?", type:"text"},
//   TokenSignatoryIdentityOrSignature: {label: "Email address to sign transaction.", type:"email"},
//   Terms: {label:"Click this button to add token terms.", type:"radio"},
//   TokenTerms: {label: "Terms:", type:"text"}
// //TokenTerms: {label: "Terms:", type:"textarea"}
// }

// const address3 = "0x99755cEBA6460491173307985ea7c0cdB0a84D7A";
// const abi3 = [
//   {
//     inputs: [
//       { internalType: "string", name: "_stamp", type: "string" },
//       { internalType: "uint256", name: "_factoryFee", type: "uint256" },
//       { internalType: "address", name: "_deployer", type: "address" },
//       { internalType: "address payable", name: "lexDAO", type: "address" },
//       { internalType: "bool", name: "certified", type: "bool" },
//       { internalType: "bool", name: "_gated", type: "bool" },
//     ],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "constructor",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       {
//         indexed: true,
//         internalType: "bool",
//         name: "updatedCertification",
//         type: "bool",
//       },
//     ],
//     name: "CertificationUpdated",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       {
//         indexed: true,
//         internalType: "uint256",
//         name: "updatedFactoryFee",
//         type: "uint256",
//       },
//     ],
//     name: "FactoryFeeUpdated",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       {
//         indexed: true,
//         internalType: "string",
//         name: "details",
//         type: "string",
//       },
//       {
//         indexed: true,
//         internalType: "uint256",
//         name: "payment",
//         type: "uint256",
//       },
//     ],
//     name: "LexDAOPaid",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       {
//         indexed: true,
//         internalType: "address",
//         name: "updatedLexDAO",
//         type: "address",
//       },
//     ],
//     name: "LexDAOUpdated",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: true, internalType: "address", name: "LT", type: "address" },
//       {
//         indexed: true,
//         internalType: "address",
//         name: "owner",
//         type: "address",
//       },
//     ],
//     name: "LexTokenDeployed",
//     type: "event",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "_certified",
//     outputs: [{ internalType: "bool", name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "_lexDAO",
//     outputs: [{ internalType: "address payable", name: "", type: "address" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "deployer",
//     outputs: [{ internalType: "address", name: "", type: "address" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "factoryFee",
//     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "gated",
//     outputs: [{ internalType: "bool", name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "getLexTokenCount",
//     outputs: [
//       { internalType: "uint256", name: "LexTokenCount", type: "uint256" },
//     ],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { internalType: "string", name: "name", type: "string" },
//       { internalType: "string", name: "symbol", type: "string" },
//       { internalType: "string", name: "_stamp", type: "string" },
//       { internalType: "uint8", name: "decimals", type: "uint8" },
//       { internalType: "uint256", name: "cap", type: "uint256" },
//       { internalType: "uint256", name: "initialSupply", type: "uint256" },
//       { internalType: "address", name: "owner", type: "address" },
//     ],
//     name: "newLexToken",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [{ internalType: "string", name: "details", type: "string" }],
//     name: "payLexDAO",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "stamp",
//     outputs: [{ internalType: "string", name: "", type: "string" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
//     name: "tokens",
//     outputs: [{ internalType: "address", name: "", type: "address" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { internalType: "bool", name: "updatedCertification", type: "bool" },
//     ],
//     name: "updateCertification",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { internalType: "uint256", name: "updatedFactoryFee", type: "uint256" },
//     ],
//     name: "updateFactoryFee",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       {
//         internalType: "address payable",
//         name: "updatedLexDAO",
//         type: "address",
//       },
//     ],
//     name: "updateLexDAO",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "version",
//     outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
// ];

//newLexToken
// const contract3 = new web3.eth.Contract(abi1, address1);

// const address4 = "0x697D15d97af389A5f7922eFECEab39A688A74A99";

// const abi4 = [
//   {
//     inputs: [
//       { internalType: "uint256", name: "_factoryFee", type: "uint256" },
//       { internalType: "address payable", name: "_lexDAO", type: "address" },
//     ],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "constructor",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "factoryFee",
//     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "lexDAO",
//     outputs: [{ internalType: "address payable", name: "", type: "address" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { internalType: "string", name: "name", type: "string" },
//       { internalType: "string", name: "symbol", type: "string" },
//       { internalType: "string", name: "_stamp", type: "string" },
//       { internalType: "uint8", name: "decimals", type: "uint8" },
//       { internalType: "address", name: "_fundsToken", type: "address" },
//       { internalType: "address[]", name: "ownership", type: "address[]" },
//       { internalType: "uint256[]", name: "issuance", type: "uint256[]" },
//     ],
//     name: "newLexSecurityToken",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "version",
//     outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
// ];

// const contract4 = new web3.eth.Contract(abi2, address2);

// ==Token Info==
// const tokenInfo2 = {
//   TokenInitialAmount: {
//     label: "How many Tokens to start? (Default is 1,000)",
//     type: "number",
//   },
//   TokenName: { label: "What is your Name or the Token Name?", type: "text" },
//   TokenSymbol: {
//     label: "What are your Initials or a the 3-5 letter token call symbol?",
//     type: "text",
//   },
//   TokenDecimals: {
//     label: "How many decimal places for the token? (Default is 18)",
//   },
//   TokenInitialOwnerEthAddress: { label: "Web3 address/account?", type: "text" },
//   TokenSignatoryIdentityOrSignature: {
//     label: "Email address to sign transaction.",
//     type: "email",
//   },
//   Terms: { label: "Click this button to add token terms.", type: "radio" },
//   TokenTerms: { label: "Terms:", type: "text" },
//   //TokenTerms: {label: "Terms:", type:"textarea"}
// };

class Gut3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenType: 0,
    };
  }

  // arguments:Token Name, Token Symbol, info.id, 18, Token Initial Amount in Wei)]]
  generatePT1 = async (e) => {
    // e.preventDefault
    console.log("generate PT");
    const account = await web3.eth.getAccounts();
    const result = await contract1.methods
      .newLexToken(
        "Alphabet Token",
        "ABC",
        "0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178",
        18,
        1000,
        1000,
        "0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178"
      )
      .send({ from: account[0] });
    console.log("result", result, account);
    return false;
  };

  // arguments:Token Name, Token Symbol, info.id, 18, Token Initial Amount in Wei)]]
  generatePT2 = async (e) => {
    // e.preventDefault
    console.log("generate PT");
    const account = await web3.eth.getAccounts();
    const result = await contract1.methods
      .newLexToken(
        "Alphabet Token",
        "ABC",
        "0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178",
        18,
        1000,
        1000,
        "0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178"
      )
      .send({ from: account[0] });
    console.log("result", result, account);
    return false;
  };

  //   {
  //     TokenInitialAmount: {label: "How many Tokens to start?", type: "number"},
  //     TokenName:{  label: "What is your Name?", type:"text"}
  //     TokenSymbol:{ label: "What are your Initials?", type:"text"}
  //     TokenInitialOwnerEthAddress: {label: "What is your Ethereum address?", type:"text"},
  //     TokenSignatoryIdentityOrSignature: {label: "What is your Email address", type:"text"},
  //     Terms: {label:"Document Terms of Token Issuance?", type:"text"},
  //     TokenTerms: {label: "Terms:", type:"checkbox"}
  // }

  //Token Name, Token Symbol, info.id, 18, Adjusted Token Supply Cap, Adjusted Token Initial Supply, Token Issuer;

  handleChange1 = (e) => {
    // e.preventDefault
    console.log("tokenType", e.target.value);
    this.setState({ tokenType: e.target.value });
    return false;
  };

  handleChange2 = (e) => {
    // e.preventDefault
    console.log("tokenType", e.target.value);
    this.setState({ tokenType: e.target.value });
    return false;
  };

  loadContract = (e) => {
    return false;
  };

  render() {
    const { tokenType } = this.state;

    return (
      <div className="container2 mb-3">
        <HeroGut3 />

        <div className="row ml-1 mr-1">
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-02.png"
                  alt="database"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="dotzero">
                      &#128280;
                    </span>
                  </a>
                  &nbsp; Create a Token &nbsp;
                </h4>

                <h5>
                  <strong>You can tokenize just about anything.</strong>
                </h5>
                <p className="card-text">
                  Claims begin with filing a complaint. Complaints must state a
                  claim for relief and based on some type of law. Drafting an
                  effective, persuasive, and well-pleaded complaint is one of
                  the most important steps in the litigation process.
                </p>

                <div className="openlaw-el-field openlaw-el-field-choice">
                  <label className="openlaw-el-field__label">
                    <span className="openlaw-el-field__label-text">
                      <strong>I want to tokenize my</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange1}
                      className="openlaw-el-field__select I-want-to-tokenize-my"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Self</option>
                      <option value={2}>Future</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <SelfToken />}
                {tokenType === 2 && <FutureToken />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT1}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Generate Token
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FREE TO USE SERVICE &nbsp;
                    <span role="img" aria-label="lock">
                      &#128159;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    Create a Token Now
                  </a>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-03.png"
                  alt="database"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="book">
                      &#128216;
                    </span>
                  </a>
                  &nbsp; Record and Store Evidence &nbsp;
                </h4>

                <h5>
                  <strong>
                    Transfer Digital Files to the Public Blockchain
                  </strong>
                </h5>
                <p className="card-text">
                  This text is just a place holder until the copy writers step
                  in ...... Element is a one part of many in a legal claim that
                  must be proved for the claim to succeed. Lawyers and legal aid
                  providers have a good understanding in apply rules or laws and
                  the elements to evidence of a claim. Contact a lawyer before
                  you try to build an element legal claim using the WorldLaw
                  protocols
                </p>

                <div className="worldlaw-field worldlaw-field-choice">
                  <label className="worldlaw-field__label">
                    <span className="worldlaw-field__label-text">
                      <strong>Evidence Type Selector</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange2}
                      className="worldlaw-field__select web3vm-selection"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Choice1</option>
                      <option value={2}>Choice2</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <Choice1 />}
                {tokenType === 2 && <Choice2 />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT2}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Execute Transaction
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FREE TO USE SERVICE &nbsp;
                    <span role="img" aria-label="lock">
                      &#128159;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    Record and Store Evidence Now
                  </a>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-13.png"
                  alt="imagebase"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="scale">
                      &#9878;
                    </span>
                  </a>
                  &nbsp; Parse U.S. Case Law &nbsp;
                </h4>

                <h5>
                  <strong>
                    Search and Retrieve Court Records and Case Law
                  </strong>
                </h5>
                <p className="card-text">
                  This text is just a place holder until the copy writers step
                  in ...... The Judicial Council of California has adopted jury
                  instructions that accurately convey the law using language
                  that is understandable to jurors. The Judicial Council of
                  California owns the copyright in the Jury Instructions and
                  seeks to make the instructions available to the public and
                  hereby grants members of the public a nonexclusive license to
                  reproduce or modify the jury instructions,except to any
                  commercial publisher for purposes of reproducing the
                  instructions (in any format) or publicly distributing them.
                </p>

                <div className="worldlaw-field worldlaw-field-choice">
                  <label className="worldlaw-field__label">
                    <span className="worldlaw-field__label-text">
                      <strong>Jurisdiction Selector</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange2}
                      className="worldlaw-field__select web3vm-selection"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Choice1</option>
                      <option value={2}>Choice2</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <Choice1 />}
                {tokenType === 2 && <Choice2 />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT2}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Execute Transaction
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FREE TO USE SERVICE &nbsp;
                    <span role="img" aria-label="lock">
                      &#128159;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    Parse Case Law Now
                  </a>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-09.png"
                  alt="imagebase"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="magnify">
                      &#128269;
                    </span>
                  </a>
                  &nbsp; Parse Public Records &nbsp;
                </h4>

                <h5>
                  <strong>California Public Records</strong>
                </h5>
                <p className="card-text">
                  This text is just a place holder until the copy writers step
                  in ...... The California Appellate Courts Case Information
                  System provides case information for California Supreme Court
                  and Court of Appeal cases. Case information is updated once an
                  hour throughout the business day.
                </p>

                <div className="worldlaw-field worldlaw-field-choice">
                  <label className="worldlaw-field__label">
                    <span className="worldlaw-field__label-text">
                      <strong>Web3vm Selection</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange2}
                      className="worldlaw-field__select web3vm-selection"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Choice1</option>
                      <option value={2}>Choice2</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <Choice1 />}
                {tokenType === 2 && <Choice2 />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT2}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Execute Transaction
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SMALL TX FEE REQUIRED &nbsp;
                    <span role="img" aria-label="lock">
                      &#128279;
                    </span>
                    <span role="img" aria-label="lock">
                      &#9981;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    Use Web3vm Now
                  </a>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-10.png"
                  alt="search"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="peeps">
                      &#128101;
                    </span>
                  </a>
                  &nbsp; Verify Blockchain Records &nbsp;
                </h4>

                <h5>
                  <strong>Proof of Existence Tools </strong>
                </h5>
                <p className="card-text">
                  This text is just a place holder until the copy writers step
                  in ...... Proofers manually checking the accuracy of the
                  information provided from the WorldLaw users that are pursuing
                  a legal claim. The verification of evidence can be provided by
                  document like birth certificates, bank statements, business
                  records, etc. WorldLaw provides flexible verification
                  protocols that allow clients and agents/lawyers to define the
                  evidence that requires verification and the means by which the
                  evidence is to be verified.
                </p>

                <div className="worldlaw-field worldlaw-field-choice">
                  <label className="worldlaw-field__label">
                    <span className="worldlaw-field__label-text">
                      <strong>Select Blockchain</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange2}
                      className="worldlaw-field__select web3vm-selection"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Choice1</option>
                      <option value={2}>Choice2</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <Choice1 />}
                {tokenType === 2 && <Choice2 />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT2}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Execute Transaction
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a href="https://github.com/worldlaw">
                    FEE REQUIRED
                    <span role="img" aria-label="lock">
                      &#128272;
                    </span>
                    <span role="img" aria-label="lock">
                      &#128273;
                    </span>
                    <span role="img" aria-label="lock">
                      &#9981;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    Verify Records Now
                  </a>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-12.png"
                  alt="evidstore"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="folder">
                      &#128194;
                    </span>
                  </a>
                  &nbsp; File a Claim
                </h4>

                <h5>
                  <strong>
                    Encrypt and file a claim on the public blockchain.
                  </strong>
                </h5>
                <p className="card-text">
                  This text is just a place holder until the copy writers step
                  in ...... Rule 1.51. California Law Enforcement
                  Telecommunications System (CLETS) information form (a)
                  Confidential CLETS Information form to be submitted to the
                  court: A person requesting protective orders under Code of
                  Civil Procedure section 527.6, 527.8, or 527.85; Family Code
                  section 6320, 6404, or 6454; Penal Code sections 18100-18205;
                  or Welfare and Institutions Code section 213.5 or 15657.03
                  must submit to the court with the request a completed
                  Confidential CLETS Information form.
                </p>

                <div className="worldlaw-field worldlaw-field-choice">
                  <label className="worldlaw-field__label">
                    <span className="worldlaw-field__label-text">
                      <strong>Select Claim Type</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange2}
                      className="worldlaw-field__select web3vm-selection"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Choice1</option>
                      <option value={2}>Choice2</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <Choice1 />}
                {tokenType === 2 && <Choice2 />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT2}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Execute Transaction
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a href="https://github.com/worldlaw">
                    FEE REQUIRED &nbsp;
                    <span role="img" aria-label="lock">
                      &#128272;
                    </span>
                    <span role="img" aria-label="lock">
                      &#128273;
                    </span>
                    <span role="img" aria-label="lock">
                      &#9981;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    File a Claim Now
                  </a>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-01.png"
                  alt="search"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="peeps">
                      &#128101;
                    </span>
                  </a>
                  &nbsp; Manage Client Records &nbsp;
                </h4>

                <h5>
                  <strong>Encrypt and manage client and claim records. </strong>
                </h5>
                <p className="card-text">
                  This text is just a place holder until the copy writers step
                  in ...... Proofers manually checking the accuracy of the
                  information provided from the WorldLaw users that are pursuing
                  a legal claim. The verification of evidence can be provided by
                  document like birth certificates, bank statements, business
                  records, etc. WorldLaw provides flexible verification
                  protocols that allow clients and agents/lawyers to define the
                  evidence that requires verification and the means by which the
                  evidence is to be verified.
                </p>

                <div className="worldlaw-field worldlaw-field-choice">
                  <label className="worldlaw-field__label">
                    <span className="worldlaw-field__label-text">
                      <strong>Select Client Record Type</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange2}
                      className="worldlaw-field__select web3vm-selection"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Choice1</option>
                      <option value={2}>Choice2</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <Choice1 />}
                {tokenType === 2 && <Choice2 />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT2}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Execute Transaction
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a href="https://github.com/worldlaw">
                    FEE REQUIRED
                    <span role="img" aria-label="lock">
                      &#128272;
                    </span>
                    <span role="img" aria-label="lock">
                      &#128273;
                    </span>
                    <span role="img" aria-label="lock">
                      &#9981;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    Manage Client Records Now
                  </a>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4 mb-4">
            <div className="card h-100">
              <a
                href="https://github.com/worldlaw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="card-img-top"
                  src="../img/web3vm-14.png"
                  alt="evidstore"
                ></img>
              </a>
              <div className="card-body">
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span role="img" aria-label="folder">
                      &#128194;
                    </span>
                  </a>
                  &nbsp; Parse Jury Instructions
                </h4>

                <h5>
                  <strong>
                    California Jury Instructions and Rules of the Court
                  </strong>
                </h5>
                <p className="card-text">
                  This text is just a place holder until the copy writers step
                  in ...... Rule 1.51. California Law Enforcement
                  Telecommunications System (CLETS) information form (a)
                  Confidential CLETS Information form to be submitted to the
                  court: A person requesting protective orders under Code of
                  Civil Procedure section 527.6, 527.8, or 527.85; Family Code
                  section 6320, 6404, or 6454; Penal Code sections 18100-18205;
                  or Welfare and Institutions Code section 213.5 or 15657.03
                  must submit to the court with the request a completed
                  Confidential CLETS Information form.
                </p>

                <div className="worldlaw-field worldlaw-field-choice">
                  <label className="worldlaw-field__label">
                    <span className="worldlaw-field__label-text">
                      <strong>Selection Jury Rule Category</strong>
                    </span>
                    {"  "}
                    <select
                      onChange={this.handleChange2}
                      className="worldlaw-field__select web3vm-selection"
                    >
                      <option value={0}>Please choose one:</option>
                      <option value={1}>Choice1</option>
                      <option value={2}>Choice2</option>
                    </select>
                  </label>
                </div>
                {tokenType === 1 && <Choice1 />}
                {tokenType === 2 && <Choice2 />}
                {tokenType !== 0 && (
                  <button
                    onClick={this.generatePT2}
                    className="btn btn-primary btn-md mt-5 mb-3"
                  >
                    Execute Transaction
                  </button>
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  <a href="https://github.com/worldlaw">
                    FEE REQUIRED &nbsp;
                    <span role="img" aria-label="lock">
                      &#128272;
                    </span>
                    <span role="img" aria-label="lock">
                      &#128273;
                    </span>
                    <span role="img" aria-label="lock">
                      &#9981;
                    </span>
                  </a>
                </small>
                <h4 className="card-title font-weight-bold">
                  <a
                    href="https://github.com/worldlaw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr></hr>
                    Parse CA Jury Instructions Now
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container2">
          <div className="container3">
            <div className="d-flex justify-content-center mt-5">
              <div className="textdesc">
                <emwhite>
                  <strong>This is your Web3 account:</strong>
                </emwhite>{" "}
                <emyellow>
                  <span id="account">{this.props.account}</span>
                </emyellow>
                <div className="textdesc">
                  <emwhite>
                    <strong>Your unique Web3 account identicons are:</strong>
                  </emwhite>
                  {this.props.account ? (
                    <img
                      className="ml-1 rounded-5"
                      width="48"
                      height="48"
                      src={makeBlockie(this.props.account, 24).toString()}
                      alt="AcctIconImage"
                    />
                  ) : (
                    <span></span>
                  )}
                  {this.props.account ? (
                    <img
                      className="ml-1 rounded-5"
                      width="48"
                      height="48"
                      src={`data:image/png;base64,${new Identicon(
                        this.props.account,
                        24
                      ).toString()}`}
                      alt="AcctIconImage"
                    />
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gut3;
