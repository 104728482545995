import React, { Component } from 'react';

export default class SimpleAirdropToken extends Component {
  
  render() {
  return (<div className="web3vm-el-form ">
  <div className="web3vm-el-section ">
    {}
    
  </div>

  <div className="web3vm-el-section ">
    <span className="web3vm-el-section__title">Section-Title</span>

    <div className="web3vm-el-field web3vm-el-field-text ">
      <label className="web3vm-el-field__label">
        <span className="web3vm-el-field__label-text">
          Enter the token smart-contract hash &nbsp;
        </span>
        <input
          placeholder="Place token hash here"
          title="TokenHash"
          className="web3vm-el-field__input Token-Hash"
          type="text"
          size="46"
          defaultValue="0x579b5201f1fd40a2084f162A51339126FB0E88c1"
        />
      </label>
    </div>

<br></br>


    
    <br></br>
    <br></br>

        <span className="web3vm-el-field__label-text"><strong>
        Enter one Web3 address and the amount in tokens on each line. The Web3 address and amount can be separated with a comma or space as shown in the textbox below. &nbsp;
        </strong></span>
  </div>

  <br></br>

  <div className="simpleAirdropArray">
        Array to send to with amount in tokens: &nbsp;<br />
        <textarea 
        name="arrayTextBox" 
        id="arrayTextBox" 
        cols="50"
        maxlength="100"
        minlength="50"
        wrap="soft"
        ref="addresses" 
        rows="6"
        spellcheck="false" 
        placeholder="0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178, 20
      0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a, 30
      0x7B035331967075a168471DfBD23eD977f048f61E, 50">

        </textarea>
    </div>



<br></br>


  <div className="web3vm-el-section">
    <span className="web3vm-el-section__title"></span>
    <div className="web3vm-el-field web3vm-el-field-number">
      <label className="web3vm-el-field__label">
        <span className="web3vm-el-field__label-text">
        Typical Airdrop Quantity in Tokens &nbsp;
        </span>
        <input
          placeholder="Typical Airdrop Quantity in Tokens?"
          className="web3vm-el-field__input Typical-Airdrop-Quantity-in-Tokens"
          type="number"
          step="10"
          defaultValue="10"
        />
      </label>
    </div>

    
  </div>
</div>)
    }
}