import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class GutIPFSInfo3 extends Component {

render() {
return (

    <div class="container4">


  

  <div class="section">
  <div id="uploading-instructions"></div>
  <div class="container">
  <h2 class="text-primary">Do you want your files to be viewed publicly?</h2>
  <h4 class="text-center mt-0">Uploads are public. Consider encryption before sending.</h4>
  <div id="content-submit"></div>
      <hr class="divider my-4"></hr>
        <div class="row mb-20">
        <div class="col-lg-6 col-md-6 text-center">
        <div class="mt-5">
              <i class="fas fa-wind fa-3x text-primary mb-4"></i>
          <h3 class="h4 mb-2 text-primary"><strong>Encryption is specialized, so it's up to you to choose.</strong></h3>
          <h4 class="textdesc"><small><strong>This site does not encrypt files. YOU MUST ENCRYPT YOUR OWN FILES.</strong> There are several encryption algorithms to choose from.  <a href="https://freecrypt.org/?p=fenc" target="_blank" rel="noopener noreferrer" >Free Express Encryption</a> is one tool that makes encrypting a file easy. </small></h4>
        </div>
        </div>
        <div class="col-lg-6 col-md-6 text-center">
        <div class="mt-5">
              <i class="fas fa-file-image fa-3x text-primary mb-4"></i>
          <h3 class="h4 mb-2 text-primary"><strong>Files submitted here are stored on a system of public nodes.</strong></h3>
          <h4 class="textdesc"><small>This portal is used to send a copy of your file to a public network of node computers.  If your file is encrypted no-one will be able to read it's contents.  If you send an unencrypted file, the contents will be public.</small></h4>
        </div>
        </div>
        <div class="col-lg-6 col-md-6 text-center">
        <div class="mt-5">
              <i class="fab fa-ethereum fa-3x text-primary mb-4"></i>
          <h3 class="h4 mb-2 text-primary">Public blockchains keeps track of the content on the network.</h3>
          <h4 class="textdesc"><small>Once the content file is submitted and stored in a distributed node system, the cryptographic locator files is then passed on to the blockchain and recorded.  This allows for other applications to utilize the file for specific operations.</small></h4>
        </div>
        </div>
        <div class="col-lg-6 col-md-6 text-center">
        <div class="mt-5">
              <i class="fas fa-gas-pump fa-3x text-primary mb-4"></i>
          <h3 class="h4 mb-2 text-primary">Every transaction needs a little fuel to process the transaction.</h3>
          <h4 class="textdesc"><small>Every transaction that is sent to the blockchain uses a processor that requires a fee to place objects onto the permanent public blockchain.  Retrieval of the data is free once the content has been recorded.</small></h4>
        </div>
        </div>
        </div>
        </div>
  
        <div id="magic"></div>
  
  </div>
  
  
  <hr></hr>
  
  <div class="container">
  <div class="row justify-content-center">
  <h4 class="textdesc"><small><strong><emred>DO NOT SUBMIT UNENCRYPTED FILES UNLESS YOU INTEND THEM TO BE PUBLICLY VIEWED</emred></strong></small></h4>
  <br></br>
  </div>
  </div>
  <hr></hr>
  
  
  
  <div class="container">
  <div class="row">
  <div class="col-lg-6 col-md-6">
  <img class="img-fluid rounded" src="../img/gears0.png" alt="WL-ltgr-logos-01"></img>
  </div>
  
  
    <br></br>
    <br></br>
    <br></br>
  
  
  
  <div class="col-lg-6 col-md-6">
  
    
  <h2>There are many great free tools that encrypt information.</h2>
  <p>The following free on-line tool creates a downloadable encrypted file for you. You will receive a link to an encrypted file to download to your device. <a href="https://encrypt.one/" target="_blank" rel="noopener noreferrer" > <hr></hr>1. You will receive a link to an encrypted file to download to your device. <hr></hr> 2. Select it and submit it using the button to permanently save it to the public nodes and blockchain.</a><hr></hr>
  3. Select that file and submit it usinjg the button to permanently save it to the public nodes and blockchain.</p>
  
  </div>
  </div>
  
  <div class="container">
  <div class="row">
  <div class="col-lg-6 col-md-6">
  
  
  <img class="img-fluid rounded" src="../img/towers.png" alt="WL-ltgr-logos-01"></img>
  </div>
  
  <div class="col-lg-10">
  <br></br>
  <br></br>
  <br></br>
  <h2><a href="https://encrypt.one/" target="_blank" rel="noopener noreferrer">Create an uploadable encrypted file here.</a></h2>
  <p><a href="https://encrypt.one/" target="_blank" rel="noopener noreferrer">You will receive a link to an encrypted file to download to your device. </a>Once you have an encrypted file you can safely upload it above by selecting "SUBMIT". <br></br>
  Select it and submit it usinjg the button to permanently save it to the public nodes and blockchain.<div className="justify-content-center text-info mt-2">
      
  <a href="https://freecrypt.org/?p=fdec" target="_blank" rel="noopener noreferrer" >Free Decode Content File AFTER Submit</a>
  <br></br> 
  <hr></hr>
  <a href="#uploading-instructions" target="_blank">Instructions Here</a>
  </div>
  </p>
  
  </div>
  </div>
  
  
  </div>
  
  </div>
  </div>


);
}
}

export default GutIPFSInfo3;
