import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class GutIPFSInfo2 extends Component {

render() {
return (

<div class="container4">

<div class="row ml-2 mr-2">
<div class="col-lg-6 ">

<div id="magicEncryption"></div>

<h2>Web3bits protocols provide encrypted storage and retrieval of information from blockchains and content nodes.</h2>
<p>A virtual collection of smart-contracts that run on a system of public nodes allow public permanent storage of files along with executable code.</p>
<ul>
<li>
<strong>Web3vm.eth Encrypts Data Locally – Your unencrypted data never leaves your device.</strong>
</li>
<li>Mnemonic triggered decode keys</li>
<li>Proxy re-encryption for down-the-line viewing</li>
<li>Immutability on Ethereum public blockchain</li>
<li>Evidential content files encrypted and stored on public nodes</li>
</ul>
<p class="textdesc"><small><strong>NOTE ON ENCRYPTION</strong>: All content files should be encrypted before the files are copied to the node system. The encrypted files are saved in pieces in a distributed system with no single point of failure. This assures the files are available 24/7/365 presumably forever in an encrypted state until the Web3vm protocols locate, reassemble, and ultimately decode the files based on the original owner of the files direct. Only the original party who creates the evidence files will have the decode key. Proxy keys and re-encryption services are available to make down-the-line viewing by court officers who require file access to adjudicate claims.</small></p>
</div>
<div class="col-lg-6">
<img class="img-fluid rounded" src="https://ipfs.infura.io/ipfs/QmWnWjY49hmvX9TG6gSi6DV91zWNQKDuxdoiA88Bqnhh79" alt="Web3bits"></img>
<p class="text-muted font-italic">Recorded transactions are available anytime for viewing. Public blockchains are a reliable resource for proving the existence of records, claim, and testimonial statements.</p>
</div>
</div>

</div>

);
}
}

export default GutIPFSInfo2;
