import React, { Component } from "react";
// import Identicon from "identicon.js";
// import makeBlockie from "ethereum-blockies-base64";

class GutWeb3vmInfo extends Component {
  render() {
    return (
      <div class="container4">
        <section class="page-section mt-10" id="services">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h2 class="section-heading">
                  One-stop-shop for Web3 applications.
                </h2>
                <h4 class="service-heading">
                  Simple easy to use programs for transacting with the
                  Ethereum&nbsp;<i class="fab fa-ethereum fa-1x"></i>{" "}
                  blockchain.
                </h4>
                <h3 class="section-subheading text-muted">
                  The protocols are easy-to-understand smart-contracts that
                  interact with a network of public nodes and the Ethereum
                  blockchain. Images, documents, video and audio content files
                  are encrypted and stored on a public system of node computer.
                </h3>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12 col-lg-4">
                <span class="fa-stack fa-3x">
                  <i class="fas fa-circle fa-stack-2x text-primary "></i>
                  <i class="fas fa-lock-open fa-stack-1x fa-inverse "></i>
                </span>
                <h4 class="service-heading">
                  Web3 supports encryption. Once a file is encrypted it can only
                  be viewed with a digital decode key.{" "}
                </h4>
                <p class="text-muted">
                  When a Web3bits application is executed it creates an
                  immutable transaction that can be used as proof of the
                  existence of a electronic file. ...
                </p>
              </div>
              <div class="col-md-12 col-lg-4">
                <span class="fa-stack fa-3x">
                  <i class="fas fa-circle fa-stack-2x text-primary "></i>
                  <i class="fas fa-balance-scale fa-stack-1x fa-inverse "></i>
                </span>
                <h4 class="service-heading">
                  Time-stamped files stored on public blockchains satisfy
                  Federal Rules of Evidence.
                </h4>
                <p class="text-muted">
                  All public court systems are stressed to receive and manage
                  electronic evidence. ....
                </p>
              </div>
              <div class="col-md-12 col-lg-4">
                <span class="fa-stack fa-3x">
                  <i class="fas fa-circle fa-stack-2x text-primary "></i>
                  <i class="fas fa-link fa-stack-1x fa-inverse "></i>
                </span>
                <h4 class="service-heading">
                  The content files are persistently stored on public nodes and
                  blockchains.
                </h4>
                <p class="text-muted">
                  Claim transactions are secured and available 24/7/365. Courts
                  can rely on the public blockchain as proof of the existence of
                  a claim file without having to view the contents until the
                  court requires it. This keeps confidential files secure while
                  comporting with ethical rules of confidentiality and client
                  attorney privileges.
                </p>
              </div>
            </div>
          </div>

          <div class="container mb-0">
            <h1 class="my-1 text-black font-weight-bold text-primary">
              <br></br>
              <br></br>Basic Web3bits Use
            </h1>
            <h2 class="my-1 text-black">
              It's a digital vending machine always stocked with your favorite
              apps.
            </h2>
            <h4 class="my-1 text-muted">
              Simply connect any Web3 account and browser and interact with
              blockchains.
            </h4>
            <br></br>

            <div class="row mb-0">
              <div class="col-lg-12 mb-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    <strong>Encrypt and Upload an Image</strong>
                  </h4>
                  <div class="card-body">
                    <p class="textdesc">
                      A transaction requires a signing account. It can be a
                      human readable account like{" "}
                      <strong>SamEvidenceFile2020</strong>.
                      <strong>
                        <emblue>evidencer.eth</emblue>
                      </strong>{" "}
                      or a long cryptographic hash.
                    </p>
                  </div>
                  <div class="card-footer">
                    <a href="#magic" class="btn btn-primary">
                      Encrypt and Upload Now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    <strong>Parse Records from Blockchain Nodes</strong>
                  </h4>
                  <div class="card-body">
                    <p class="textdesc">
                      {" "}
                      Smart-contracts connect to nodes and locate all of the
                      pieces if the encrypted file and reassembles it back to
                      the original encrypted form. The files can be verified by
                      its existence, but cannot be viewed without the decode key
                      that was set when the file was originally encrypted.
                    </p>
                  </div>
                  <div class="card-footer">
                    <a href="#magic" class="btn btn-primary">
                      Parse Blockchain Records
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    <strong>Decode Encrypted Files</strong>
                  </h4>
                  <div class="card-body">
                    <p class="textdesc">
                      The claimant is the master of the evidence and claim file.
                      Based on the claimant’s strategy along with the assistance
                      of Bluefin protocols used by lawyers/agents the files can
                      be re-encrypted for down-the-line viewing by opposing
                      parties and triers of fact, like juries.
                    </p>
                  </div>
                  <div class="card-footer">
                    <a href="#magic" class="btn btn-primary">
                      Decode Encrypted Files
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default GutWeb3vmInfo;
