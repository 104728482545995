import React, { Component } from 'react';

export default class SimpleAirdropEther extends Component {
  
  render() {
    return (<div className="web3vm-el-form ">
    <div className="web3vm-el-section ">
      {}
      
    </div>
  
    <div className="web3vm-el-section ">
      <span className="web3vm-el-section__title">Section-Title</span>
  
  
      <div className="web3vm-el-field web3vm-el-field-ethaddress">
        <label className="web3vm-el-field__label ">
          <span className="web3vm-el-field__label-text">
          What Web3 address do you want to send from?  &nbsp;
          </span>
          <input
            placeholder="What Web3 address do you want to send from? "
            title="What Web3 address do you want to send from? "
            className="web3vm-el-field__input Token-Issuer"
            type="text"
            size="46"
            defaultValue="paste a Web3 address here"
          />
        </label>
      </div>
      
      <br></br>
      <br></br>
  
          <span className="web3vm-el-field__label-text"><strong>
          Enter one Web3 address and the amount in Finney on each line. The Web3 address and amount can be separated with a comma or space as shown in the textbox below. &nbsp;
          </strong></span>
    </div>
  
    <br></br>
  
    <div className="simpleAirdropArray">
          Array to send to with ether amount in Finney (1 finney = 1/1000 Ether): &nbsp;<br />
          <textarea 
          name="arrayTextBox" 
          id="arrayTextBox" 
          cols="50"
          maxlength="100"
          minlength="50"
          wrap="soft"
          ref="addresses" 
          rows="6"
          spellcheck="false" 
          placeholder="0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178 1
        0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a, 1
        0x7B035331967075a168471DfBD23eD977f048f61E, 1">
  
          </textarea>
      </div>
  
  
  
  <br></br>
  
  
    <div className="web3vm-el-section">
      <span className="web3vm-el-section__title"></span>
      <div className="web3vm-el-field web3vm-el-field-number">
        <label className="web3vm-el-field__label">
          <span className="web3vm-el-field__label-text">
          Typical Airdrop Quantity in Finney &nbsp;
          </span>
          <input
            placeholder="Typical Airdrop Quantity in Finney?"
            className="web3vm-el-field__input Typical-Airdrop-Quantity-in-Finney"
            type="number"
            step="10"
            defaultValue="10"
          />
        </label>
      </div>
  
      
    </div>
  </div>)
      }
  }