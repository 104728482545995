import React, { Component } from 'react';

export default class NewToken extends Component {
  
  render() {
  return (<div className="openlaw-el-form">
  <div className="openlaw-el-section">
    {/* <span className="openlaw-el-section__title"></span> */}

  </div>
  <div className="openlaw-el-section">
    <span className="openlaw-el-section__title"></span>
    <div className="openlaw-el-field openlaw-el-field-text">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">
          What is your Name? (Tkn Name) 
        </span>
        <input
          placeholder="What is your Name? (Tkn Name) "
          title="What is your Name? (Tkn Name) "
          className="openlaw-el-field__input Token-Name"
          type="text"
          defaultValue
        />
      </label>
    </div>
    <div className="openlaw-el-field openlaw-el-field-text">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">
          What are your Initials? (Tkn Symbol) 
        </span>
        <input
          placeholder="What are your Initials? (Tkn Symbol) "
          title="What are your Initials? (Tkn Symbol) "
          className="openlaw-el-field__input Token-Symbol"
          type="text"
          defaultValue
        />
      </label>
    </div>
    <div className="openlaw-el-field openlaw-el-field-ethaddress">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">
          What is your ETH address to launch from? 
        </span>
        <input
          placeholder="What is your ETH address to launch from? "
          title="What is your ETH address to launch from? "
          className="openlaw-el-field__input Token-Issuer"
          type="text"
          defaultValue
        />
      </label>
    </div>
    <div className="openlaw-el-field openlaw-el-field-identity">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">
          What is your Email? 
        </span>
        <input
          placeholder="What is your Email? "
          title="What is your Email? "
          className="openlaw-el-field__input Token-Signatory"
          type="email"
          defaultValue
        />
      </label>
    </div>
  </div>
  <div className="openlaw-el-section">
    <span className="openlaw-el-section__title"></span>
    <div className="openlaw-el-field openlaw-el-field-number">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">
          Starting token supply? 
        </span>
        <input
          placeholder="Starting token supply? "
          className="openlaw-el-field__input Token-Initial-Supply"
          type="number"
          defaultValue
        />
      </label>
    </div>
    <div className="openlaw-el-field openlaw-el-field-number">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">
          Supply cap? This will be fixed.
        </span>
        <input
          placeholder="Supply cap? This will be fixed. "
          className="openlaw-el-field__input Token-Supply-Cap"
          type="number"
          defaultValue
        />
      </label>
    </div>
  </div>
  <div className="openlaw-el-section">
    <span className="openlaw-el-section__title">Section-Title</span>
    <div className="openlaw-el-field openlaw-el-field-yesno">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">Attach Logo? </span>
      </label>
      <div>
        <label>
          <input
            className="openlaw-el-field__radio Image"
            name="Image"
            type="radio"
            defaultValue="true"
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            className="openlaw-el-field__radio Image"
            name="Image"
            type="radio"
            defaultValue="false"
          />
          <span>No</span>
        </label>
      </div>
    </div>
    <div className="openlaw-el-field openlaw-el-field-yesno">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">Attach Offers?</span>
      </label>
      <div>
        <label>
          <input
            className="openlaw-el-field__radio Terms"
            name="Terms"
            type="radio"
            defaultValue="true"
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            className="openlaw-el-field__radio Terms"
            name="Terms"
            type="radio"
            defaultValue="false"
          />
          <span>No</span>
        </label>
      </div>
    </div>
    <div className="openlaw-el-field openlaw-el-field-yesno">
      <label className="openlaw-el-field__label">
        <span className="openlaw-el-field__label-text">
          Attach Consulting ToS?
        </span>
      </label>
      <div>
        <label>
          <input
            className="openlaw-el-field__radio Themis-ToS"
            name="Themis-ToS"
            type="radio"
            defaultValue="true"
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            className="openlaw-el-field__radio Themis-ToS"
            name="Themis-ToS"
            type="radio"
            defaultValue="false"
          />
          <span>No</span>
        </label>
      </div>
    </div>
  </div>
</div>)
    }
}