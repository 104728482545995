import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class GutIPFSInfo0 extends Component {

render() {
return (


<div class="container4">

<section class="page-section mt-12" id="services">


</section>


</div>

);
}
}


export default GutIPFSInfo0;
