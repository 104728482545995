import React, { useState } from "react";
// import React, { useState, useEffect } from "react";
import {
    Carousel
} from "react-bootstrap";

function HeroGutIcons() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="../img/banner-ssc.png"
                    alt="Web3vm"
                />
                <Carousel.Caption>
                    <h3>.</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="../img/banner-sdttpb.png"
                    alt="Web3vm"
                />

                <Carousel.Caption>
                    <h3>.</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="../img/banner-jwr2.png"
                    alt="Web3vm"
                />

                <Carousel.Caption>
                    <h3>.</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>
 


        </Carousel>
    );
}

export default HeroGutIcons