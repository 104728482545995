import React, { Component } from 'react';
import SimpleAirdropEther from "./SimpleAirdropEther.js";
import SimpleAirdropTokens from "./SimpleAirdropTokens.js"
import Web3 from 'web3';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';
import HeroGutSimpleAirdrop from "./HeroGutSimpleAirdrop.js"



const web3 = new Web3(Web3.givenProvider)

const address1 = "0xD152f549545093347A162Dce210e7293f1452150"
const abi1 = [{"constant":false,"inputs":[{"name":"token","type":"address"},{"name":"recipients","type":"address[]"},{"name":"values","type":"uint256[]"}],"name":"disperseTokenSimple","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":false,"inputs":[{"name":"token","type":"address"},{"name":"recipients","type":"address[]"},{"name":"values","type":"uint256[]"}],"name":"disperseToken","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":false,"inputs":[{"name":"recipients","type":"address[]"},{"name":"values","type":"uint256[]"}],"name":"disperseEther","outputs":[],"payable":true,"stateMutability":"payable","type":"function"}]
;


//AirDrop
const contract1 = new web3.eth.Contract(abi1, address1)


// const address2 = "0xD152f549545093347A162Dce210e7293f1452150"

// const abi2 = [{"constant":false,"inputs":[{"name":"token","type":"address"},{"name":"recipients","type":"address[]"},{"name":"values","type":"uint256[]"}],"name":"disperseTokenSimple","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":false,"inputs":[{"name":"token","type":"address"},{"name":"recipients","type":"address[]"},{"name":"values","type":"uint256[]"}],"name":"disperseToken","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":false,"inputs":[{"name":"recipients","type":"address[]"},{"name":"values","type":"uint256[]"}],"name":"disperseEther","outputs":[],"payable":true,"stateMutability":"payable","type":"function"}]

// const contract2 = new web3.eth.Contract(abi2, address2)

class GutSimpleAirdrop extends Component {

constructor(props){
  super(props);
  this.state = {
    tokenType: 0
  }
}

  // arguments:TokenHash, ArrayOfDrop, Token Initial Amount in Wei)]]
  sendAirdrop = async (e) =>{
    // e.preventDefault
    console.log("generate Airdrop");
    const account = await web3.eth.getAccounts()
    const result = await contract1.methods.disperseEther( ['0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178', '0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a', '0x7B035331967075a168471DfBD23eD977f048f61E'], ['1', '2', '3'] ).send({from: account[0]})
    console.log("result",result,account)
    return false;
  }


  handleChange = (e) =>{
    // e.preventDefault
    console.log("airdropTransaction",e.target.value);
    this.setState({tokenType: e.target.value})
    return false;
  }
  

  loadContract = (e) =>{


    return false;
  }
  
  
  render() {
    const { tokenType} = this.state;
    return (

<div className="container2 mb-5">

<section className="call-to-action text-white mb-5">

<HeroGutSimpleAirdrop />

<div className="col-md-10 col-lg-8 col-xl-7 mx-auto">

<div className="form-row">
  
<p className="bigtextgold mt-5"><strong>Airdrop Ether or Tokens to Multiple Web3 accounts</strong></p>
<p class="textdesc"><emwhite>This text is just a place holder until the copy writers step in ...... The protocol retrieves blocks and chunks of data for reassembly and decoding into a useable/viewable file. The decoded files include electronic documents with timestamps and in some cases, admissible direct testimony</emwhite></p>
<p>WorldLaw is a set of protocols that operate with public nodes, specifically Ethereum, Swarm and IPFS to store encrypted content and data-files for court use.</p>

<br></br>

<p className="bigtextgold"><strong>Transfer value to Web3 accounts with a two clicks.</strong></p>
<p class="textdesc"><emwhite>This text is just a place holder until the copy writers step in ...... The protocol retrieves blocks and chunks of data for reassembly and decoding into a useable/viewable file. The decoded files include electronic documents with timestamps and in some cases, admissible direct testimony</emwhite></p>
<p>WorldLaw is a set of protocols that operate with public nodes, specifically Ethereum, Swarm and IPFS to store encrypted content and data-files for court use.</p>

<p></p>
<p></p>
<p></p>
<p className="bigtextlime"><strong>Transfer Tokens or Ether Now</strong></p>  
<div className="col-xl-7 col-lg-8 col-md-10 mx-auto mb-5">
  
  <div className="web3vm-el-field web3vm-el-field-choice">
      <label className="web3vm-el-field__label">
        <span className="web3vm-el-field__label-text">
          It's fun. &nbsp;
        </span>{'  '}
        <select onChange={this.handleChange} className="web3vm-el-field__select I-want-to-tokenize-my">
        <option value={0} >Please choose one:</option>
          <option value={1}>Send Ether</option>
          <option value={2}>Send Tokens</option>
        </select>
      </label>
    </div>
    {tokenType === 1 && <SimpleAirdropEther /> }
    {tokenType === 2 && <SimpleAirdropTokens /> }
{tokenType !==  0 && <button onClick={this.sendAirdrop}  className="btn btn-primary btn-md mt-5 mb-3">Send Transaction</button>}
</div>

</div>

</div>


</section>

<div className="container3 ">
<div className="d-flex justify-content-center mt-5">


<div className="textdesc"><emwhite><strong>This is your Web3 account:</strong></emwhite> <emyellow><span id="account">{this.props.account}</span></emyellow>
<div className="textdesc"><emwhite><strong>Your unique Web3 account identicons are:</strong></emwhite>
{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='48'
                height='48'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }   
            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='48'
                height='48'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
                : <span></span>
              }

</div>
</div>
</div>
</div>

</div>

);
}
}

export default GutSimpleAirdrop;
