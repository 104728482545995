import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



const Web3 = require('web3')
const web3 = new Web3('https://mainnet.infura.io/v3/8db5dad32a3f490dac7aaf6cb2b23b82')

web3.eth.accounts.create()
const newAccount = web3.eth.accounts.create()
console.log(newAccount)



class GutBc extends Component {

  render() {
    return (

<div className="container3">
<div className="d-flex justify-content-center mt-5">
<div className="textdesc"><emwhite><strong>This is your Web3 account: </strong></emwhite><emyellow><span id="account">{this.props.account}</span></emyellow>
<div className="textdesc"><emwhite><strong>Your unique Web3 account identicons are:</strong></emwhite>
{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='48'
                height='48'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }   
            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='48'
                height='48'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
                : <span></span>
              }

</div>
</div>
</div>
</div>


);
}
}

export default GutBc;
